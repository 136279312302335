'use client'

import { css, ResponsiveStyleValue, variant } from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { TypoSizeProps, typoSizes } from '../typography'
import { Anchor, AnchorProps } from './Anchor'

export type FooterAnchorProperty = 'default'

export interface FooterAnchorProps extends AnchorProps, TypoSizeProps {
  variant?: ResponsiveStyleValue<FooterAnchorProperty>
}

const defaultProps = withDefaultProps<FooterAnchorProps>({
  variant: 'default',
  typoSize: 'sm',
})

export const FooterAnchor = styled(Anchor).attrs<FooterAnchorProps>(defaultProps)(
  css({
    fontFamily: 'navbar',
    color: 'textFooter',
    whiteSpace: 'nowrap',
  }),
  typoSizes,
  variant({
    scale: 'FooterAnchor',
    variants: {
      default: {},
    },
  }),
)
