'use client'

import {
  BackgroundColorProps,
  border,
  BorderProps,
  color,
  compose,
  css,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from '@wrisk/styled-system'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { transition, TransitionProps } from '../../transition'
import { containerStyle, ContainerStyleProps } from './containerStyle'
import { displayOn, DisplayOnProps } from './displayOn'

export type BoxProps = BackgroundColorProps &
  BorderProps &
  ContainerStyleProps &
  DisplayOnProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  PropsWithChildren &
  SpaceProps &
  TransitionProps

export const Box = styled.div<BoxProps>(
  css({
    borderColor: 'divider',
    borderStyle: 'solid',
    borderWidth: 0,
  }),
  containerStyle,
  displayOn,
  compose(layout, position, space, border, color, transition, flexbox, grid),
)
