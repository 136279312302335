import { css, ResponsiveStyleValue, variant } from '@wrisk/styled-system'
import { animated } from 'react-spring'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'

export type AnimatedMenuProperty = 'default' | 'manageQuote'

export interface AnimatedMenuProps {
  variant?: ResponsiveStyleValue<AnimatedMenuProperty>
}

const defaultProps = withDefaultProps<AnimatedMenuProps>({
  variant: 'default',
})

export const AnimatedMenu = styled(animated.div).attrs<AnimatedMenuProps>(defaultProps)(
  css({
    bg: 'chalk',
    borderRadius: 1,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    zIndex: 999,
  }),
  variant({
    variants: {
      default: {
        mt: 2,
        right: 0,
        top: '100%',
        width: '304px',
      },
      manageQuote: {
        mt: 8,
        alignItems: 'flex-start',
        width: 'auto',
        boxShadow: 'popupMenu',
      },
    },
  }),
)
