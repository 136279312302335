import { ChatClient } from './types'

export const adminChat: ChatClient = {
  show: () => {},
  register: () => {
    console.log('Registering admin chat...')
  },
  shutDown: () => {},
  updateUser: () => {},
}
