export type ChangeConfig =
  | ProfileChangeConfig
  | AssetChangeConfig
  | QuoteChangeConfig
  | StartDateChangeConfig
  | VoluntaryExcessChangeConfig
  | RequestCoverOptionChangeConfig

export enum ChangeType {
  ASSET_DISCLOSURE = 'ASSET_DISCLOSURE',
  PROFILE_DISCLOSURE = 'PROFILE_DISCLOSURE',
  QUOTE_DISCLOSURE = 'QUOTE_DISCLOSURE',
  START_DATE_CHANGE = 'START_DATE_CHANGE',
  VOLUNTARY_EXCESS = 'VOLUNTARY_EXCESS',
  REQUEST_COVER_OPTION = 'REQUEST_COVER_OPTION',
}

export interface QuoteChangeConfig {
  changeType: ChangeType.QUOTE_DISCLOSURE
  update: AdjustmentUpdate
}

export interface ProfileChangeConfig {
  changeType: ChangeType.PROFILE_DISCLOSURE
  update: AdjustmentUpdate
}

export interface AssetChangeConfig {
  changeType: ChangeType.ASSET_DISCLOSURE
  update: AdjustmentUpdate
}

export interface StartDateChangeConfig {
  changeType: ChangeType.START_DATE_CHANGE
}

export interface VoluntaryExcessChangeConfig {
  changeType: ChangeType.VOLUNTARY_EXCESS
}

export interface RequestCoverOptionChangeConfig {
  changeType: ChangeType.REQUEST_COVER_OPTION
  coverCode: string
}

export interface Update {
  path: string
  attribute?: boolean
}

export interface NestedUpdate extends Update {
  id: string
}

export type AdjustmentUpdate = Update | Array<NestedUpdate>
