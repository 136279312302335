import {
  color,
  ColorProps,
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { transition, TransitionProps } from '../../transition'
import { whiteSpace, WhiteSpaceProps } from '../typography'
import { Button, ButtonProps } from './Button'
import {
  buttonDisabledVariants,
  ButtonLayoutProps,
  buttonLayoutVariant,
  buttonLoadingVariants,
  buttonStateMapping,
  ButtonStateProps,
  defaultButtonStyles,
} from './variants'

export type GhostButtonProps = ButtonProps &
  ButtonLayoutProps &
  LayoutProps &
  SpaceProps &
  ColorProps &
  TransitionProps &
  WhiteSpaceProps

const defaultProps = withDefaultProps<GhostButtonProps>({
  layout: 'default',
})

export const GhostButton = styled(Button)
  .attrs<ButtonStateProps>(buttonStateMapping)
  .attrs<GhostButtonProps>(defaultProps)(
  defaultButtonStyles,
  variant({
    prop: '$loadingState',
    scale: 'GhostButton.loading',
    variants: buttonLoadingVariants,
  }),
  variant({
    prop: '$disabledState',
    scale: 'GhostButton.disabled',
    variants: buttonDisabledVariants,
  }),
  buttonLayoutVariant,
  compose(layout, space, color, transition, whiteSpace),
)
