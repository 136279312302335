import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  css,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { collapse, CollapseProps } from './collapse'
import { wideContainerWidths } from './containerWidths'
import { gap, GapProps } from './gap'

export type WideContainerProperty = 'default' | 'sidebar'

export interface WideContainerProps
  extends BorderProps,
    CollapseProps,
    ColorProps,
    FlexboxProps,
    GapProps,
    LayoutProps,
    SpaceProps {
  variant?: WideContainerProperty
}

const defaultProps = withDefaultProps<WideContainerProps>({
  variant: 'default',
})

export const WideContainer = styled.div.attrs<WideContainerProps>(defaultProps)(
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
    width: wideContainerWidths,
    minWidth: wideContainerWidths,
    mx: 'auto',
  }),
  variant({
    scale: 'WideContainer',
    variants: {
      default: {},
      sidebar: {
        flexDirection: ['column', 'column', 'row-reverse'],
        justifyContent: ['flex-start', 'flex-start', 'center'],
        alignItems: 'flex-start',
      },
    },
  }),
  compose(flexbox, space, layout, collapse, gap, border, color),
)
