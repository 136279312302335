import { Icon, Notification, NotificationProps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { StripeMotoSettleError } from '../../../clients/api/clients/paymentCard'
import {
  TKeyBuilder,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export interface AdminSettleErrorNotificationProps extends NotificationProps {
  tKey: TKeyBuilder
  data?: StripeMotoSettleError
}

export const AdminSettleErrorNotification: FunctionComponent<
  AdminSettleErrorNotificationProps
> = ({ data, tKey, ...props }) => {
  const { t } = useWriskTranslation()

  const unknownError = t(tKey('errors', 'unknown'))

  const message = (() => {
    switch (data?.type) {
      case 'timeout':
      case 'incomplete':
        return t(tKey('errors', data.type), { defaultValue: unknownError })
      default:
        return data?.message ?? unknownError
    }
  })()

  return (
    <Notification
      type='standalone'
      variant='critical'
      alignItems='flex-start'
      justifyContent='flex-start'
      gap={4}
      {...props}
    >
      <Icon icon='warning' />
      <Typo>
        <span>{message}</span>
        {data?.cardDeclineCode && <i> ({data.cardDeclineCode})</i>}
      </Typo>
    </Notification>
  )
}
