import {
  border,
  BorderProps,
  compose,
  css,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { LinkAnchor } from '../anchors'
import { Grid, GridProps } from '../layout'
import { mdBumps, smBumps } from '../layout/bumps'
import { gap, GapProps } from './gap'

export type NotificationVariants = 'default' | 'success' | 'warning' | 'critical' | 'info'
export type NotificationLayouts = 'default' | 'small'
export type NotificationTypes = 'nested' | 'standalone'

export interface NotificationProps
  extends BorderProps,
    SpaceProps,
    LayoutProps,
    FlexboxProps,
    GapProps,
    PropsWithChildren {
  variant?: NotificationVariants
  layout?: NotificationLayouts
  type?: NotificationTypes
}

export const NotificationContainer: FunctionComponent<GridProps> = ({
  children,
  ...props
}) => (
  <Grid rowGap={4} width={1} collapseWhenEmpty={true} {...props}>
    {children}
  </Grid>
)

const defaultProps = withDefaultProps<NotificationProps>({
  variant: 'default',
  layout: 'default',
  type: 'nested',
})

export const Notification = styled.div.attrs<NotificationProps>(defaultProps)(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [LinkAnchor]: {
      color: 'body',
      borderColor: 'body',
      '&:hover': {
        color: 'bodySecondary',
        borderColor: 'bodySecondary',
      },
    },
  }),
  variant({
    prop: 'layout',
    scale: 'Notification.layouts',
    variants: {
      default: {
        p: mdBumps,
      },
      small: {
        p: smBumps,
      },
    },
  }),
  variant({
    prop: 'type',
    scale: 'Notification.type',
    variants: {
      nested: {
        borderRadius: 1,
        borderWidth: 0,
      },
      standalone: {
        borderRadius: 2,
        borderWidth: 1,
        borderStyle: 'solid',
      },
    },
  }),
  variant({
    scale: 'Notification.variants',
    variants: {
      default: {
        bg: 'surfaceHighlight',
        borderColor: 'body',
      },
      success: {
        bg: 'surfaceSuccessSubdued',
        borderColor: 'surfaceSuccess',
      },
      warning: {
        bg: 'surfaceWarningSubdued',
        borderColor: 'surfaceWarning',
      },
      critical: {
        bg: 'surfaceCriticalSubdued',
        borderColor: 'surfaceCritical',
      },
      info: {
        bg: 'surfaceInfoSubdued',
        borderColor: 'surfaceInfo',
      },
    },
  }),
  compose(space, layout, border, flexbox, gap),
)
