import { css, variant } from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'

export type AppNameProperty = 'default'

export interface AppNameProps {
  variant?: AppNameProperty
}

const defaultProps = withDefaultProps<AppNameProps>({
  variant: 'default',
})

export const AppName = styled.div.attrs<AppNameProps>(defaultProps)(
  css({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'navbar',
    width: '100%',
  }),
  variant({
    scale: 'AppName',
    variants: {
      default: {
        display: 'none',
      },
    },
  }),
)
