import { isValidVRN } from '@wrisk/ui-components'

import { ValidationFunctions } from '../types'

export const vehicleRegistration: ValidationFunctions = {
  _self: {
    required: (isRequired: boolean) => (value: string) =>
      !isRequired || isValidVRN(value),
  },
}
