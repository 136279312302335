import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
} from 'react'

import { CustomerPayment } from '../../domain'

export interface CustomerPaymentContextObj {
  customerPayment: CustomerPayment | undefined
}

const CustomerPaymentContext = createContext<CustomerPaymentContextObj | undefined>(
  undefined,
)

interface CustomerPaymentProviderProps extends PropsWithChildren {
  customerPayment: CustomerPayment
}

export const CustomerPaymentProvider: FunctionComponent<CustomerPaymentProviderProps> = ({
  customerPayment,
  children,
}) => {
  return (
    <CustomerPaymentContext.Provider value={{ customerPayment }}>
      {children}
    </CustomerPaymentContext.Provider>
  )
}

export const useCustomerPayment = (): CustomerPaymentContextObj => {
  const context = useContext(CustomerPaymentContext)
  if (!context) {
    throw new Error('Cannot use CustomerPaymentContext when undefined.')
  }
  return context
}
