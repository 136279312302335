import { isNil } from 'lodash'
import { Validate } from 'react-hook-form'

import { InputConfig, ValidationConfig } from '../../../state/configuration'
import { isNotNil, raiseError, required } from './helpers'
import { ValidationFunctions } from './types'
import {
  checkbox,
  checkboxEligibility,
  checkboxPromotion,
  date,
  datePicker,
  email,
  name,
  numberText,
  phoneNumber,
  radio,
  text,
  vehicleRegistration,
  vehicleTabs,
  yearMonth,
} from './validators'

const validationMap: Record<string, ValidationFunctions> = {
  checkbox,
  checkboxPromotion,
  checkboxEligibility,
  date,
  datePicker,
  email,
  name,
  numberText,
  phoneNumber,
  radio,
  text,
  vehicleRegistration,
  vehicleTabs,
  yearMonth,
}

export const getValidationRules = (
  type: string,
  validation: ValidationConfig | undefined,
  inputs: InputConfig[],
): Record<string, Validate<unknown, unknown>> => {
  const validationRules = validationMap[type] ?? { _self: { required } }

  const { _self, ...others } = validation ?? {}

  const selfRules: Record<string, Validate<unknown, unknown>> = Object.entries(
    _self ?? {},
  ).reduce(
    (validation, [rule, option]) =>
      isNotNil(validationRules._self?.[rule])
        ? {
            ...validation,
            [rule]: validationRules._self[rule](option),
          }
        : raiseError(`Could not find validation rule ${rule}`),
    {},
  )

  const otherRules: Record<string, Validate<unknown, unknown>> = Object.entries(
    others,
  ).reduce((inputRules, [inputName, rules]) => {
    const input = inputs.find((it) => it.name === inputName)

    if (isNil(input)) {
      throw new Error(
        `No input exists with name ${inputName}. Cannot load validation rules`,
      )
    }

    const validationRule = validationRules[input.type] ?? { required }

    if (isNil(validationRule)) {
      throw new Error(`No validation rule exists for input type ${input.type}`)
    }

    return Object.entries(rules ?? {}).reduce(
      (validation, [rule, option]) =>
        isNotNil(validationRule[rule])
          ? {
              ...validation,
              [`${inputName}_${rule}`]: validationRule[rule](option, inputName),
            }
          : raiseError(
              `Could not find validation rule for type ${type}, input type ${input.type} and rule ${rule}`,
            ),
      inputRules,
    )
  }, {})

  return {
    ...selfRules,
    ...otherRules,
  }
}
