import {
  color,
  ColorProps,
  compose,
  css,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'

export type MenuBoxProperty = 'default'

export interface MenuBoxProps extends LayoutProps, SpaceProps, ColorProps {
  variant?: MenuBoxProperty
}

const defaultProps = withDefaultProps<MenuBoxProps>({
  variant: 'default',
})

export const MenuBox = styled.div.attrs<MenuBoxProps>(defaultProps)(
  css({
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  variant({
    scale: 'MenuBox',
    variants: {
      default: {},
    },
  }),
  compose(layout, space, color),
)
