import { required } from '../helpers'
import { ValidationFunctions } from '../types'

export const numberText: ValidationFunctions = {
  _self: {
    required,
    greaterThanOrEqual: (comparator: number) => (value: number) => value >= comparator,
    lessThanOrEqual: (comparator: number) => (value: number) => value <= comparator,
  },
}
