import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

import { Proposal } from '../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../../hooks/auth'
import { usePrincipal } from '../../authentication'

interface ProposalContextObj {
  proposal: Proposal
  setProposal: (proposal: Proposal) => void
  refresh: () => void
}

const ProposalContext = createContext<ProposalContextObj | undefined>(undefined)

export interface ProposalProviderProps extends PropsWithChildren {
  proposal: Proposal
}

export const ProposalProvider: FunctionComponent<ProposalProviderProps> = (props) => {
  const { apiClient } = usePrincipal()
  const [proposal, setProposal] = useState(props.proposal)

  const { execute: refresh } = useApiErrorHandlingAsyncCallback(async () => {
    setProposal(await apiClient.getProposal(proposal.proposalCode))
  })

  return (
    <ProposalContext.Provider value={{ proposal, setProposal, refresh }}>
      {props.children}
    </ProposalContext.Provider>
  )
}

export const useProposal = (): ProposalContextObj => {
  const context = useContext(ProposalContext)
  if (!context) {
    throw new Error('Cannot use ProposalContext when undefined.')
  }
  return context
}

export const useMaybeProposal = (): Proposal | undefined =>
  useContext(ProposalContext)?.proposal
