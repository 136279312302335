import {
  compose,
  css,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { mainContainerWidths } from './containerWidths'

export type MainSectionProperty = 'default'

export interface MainSectionProps extends FlexboxProps, LayoutProps, SpaceProps {
  variant?: MainSectionProperty
}

const defaultProps = withDefaultProps<MainSectionProps>({
  variant: 'default',
})

export const MainSection = styled.div.attrs<MainSectionProps>(
  defaultProps,
)<MainSectionProps>(
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: mainContainerWidths,
    minWidth: mainContainerWidths,
    mx: 'auto',
  }),
  variant({
    scale: 'MainSection',
    variants: {
      default: {},
    },
  }),
  compose(flexbox, space, layout),
)
