import { css, variant } from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { Typo } from './Typo'
import { TypoSizeProps, typoSizes } from './typoSizes'

export type PlateProperty = 'default'

export interface PlateProps extends TypoSizeProps {
  variant?: PlateProperty
}

const defaultProps = withDefaultProps<PlateProps>({
  variant: 'default',
})

export const Plate = styled(Typo).attrs<PlateProps>(defaultProps)(
  css({
    display: 'inline-block',
    whiteSpace: 'nowrap',
    backgroundColor: 'surfaceDisabled',
    my: -1,
    py: 1,
    px: 2,
    borderRadius: 2,
  }),
  variant({
    scale: 'Plate',
    variants: {
      default: {},
    },
  }),
  typoSizes,
)
