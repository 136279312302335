import { ResponsiveValue, system } from '@wrisk/styled-system'
import { Property } from 'csstype'

export interface TextTransformProps {
  textTransform?: ResponsiveValue<Property.TextTransform>
}

export const textTransform = system({
  textTransform: true,
})
