import {
  border,
  color,
  ColorProps,
  compose,
  css,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  ResponsiveValue,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from '@wrisk/styled-system'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { transition, TransitionProps } from '../../transition'
import { mdBumps } from '../layout'
import { TypoSizeProps, typoSizes } from '../typography'
import { Input, InputProps } from './Input'

export type TimeInputProperty = 'default' | 'ghost'

export type TimeInputProps = InputProps &
  TransitionProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  ColorProps &
  TypoSizeProps &
  FlexboxProps & {
    variant?: ResponsiveValue<TimeInputProperty>
    id?: string
    name?: string
    value?: string | undefined
  }

const Component: FunctionComponent<TimeInputProps> = ({ id, name, value, ...props }) => (
  <Input
    {...props}
    name={name}
    id={id}
    type='time'
    value={value}
    data-testid='time-input'
    style={{ lineHeight: '22px' }}
  />
)

const defaultProps = withDefaultProps<TimeInputProps>({
  variant: 'default',
  type: 'text',
  typoSize: 'base',
})

export const TimeInput = styled(Component).attrs<TimeInputProps>(defaultProps)(
  {
    '-webkit-appearance': 'none',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',

    // remove clock icon
    '&::-webkit-calendar-picker-indicator': {
      display: 'none',
    },
    '-moz-appearance': 'textfield',
    '&::-ms-clear': {
      display: 'none',
    },
  },
  css({
    width: '100%',
    borderRadius: 2,
    fontFamily: 'input',
    border: 0,
    zIndex: 100,
  }),
  variant({
    scale: 'TimeInput',
    variants: {
      default: {
        boxShadow: 'default',
        p: mdBumps,
        bg: 'chalk',
        '&:focus': {
          boxShadow: 'input',
        },
      },
      ghost: {
        boxShadow: 'none',
        py: mdBumps,
        px: 0,
      },
    },
  }),
  typoSizes,
  compose(transition, border, space, layout, typography, color, flexbox),
)
