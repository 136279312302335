import { PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { AnonymousUser } from '../../../clients/anonymous'
import { User } from '../../../clients/auth'
import { useAuth } from '../../../hooks/auth'
import { useApiClientFactory } from '../../../state'
import { useAuthenticate } from '../hooks'
import { PolicyholderManager } from '../policyholder'
import PrincipalProvider, { Principal } from '../PrincipalProvider'
import { isUser } from '../user'
import { AdminImpersonate } from './AdminImpersonate'
import { AdminUnauthorised } from './AdminUnauthorised'
import { ImpersonateProvider, useImpersonate } from './impersonateContext'

export const canAccessImpersonate = (
  user: User | AnonymousUser | undefined,
): user is User =>
  isUser(user) && Boolean(user.permissions.includes('access:impersonate'))

interface PrincipalManager extends PropsWithChildren {
  user: User
  impersonate: string
}

const PrincipalManager: FunctionComponent<PrincipalManager> = ({
  children,
  user,
  impersonate,
}) => {
  const apiClientFactory = useApiClientFactory()

  const auth = useAuth()

  const principal = useMemo<Principal>(
    () => ({
      user,
      impersonate,
      isAuthenticated: true,
      isAdmin: true,
      apiClient: apiClientFactory(auth.getTokenSilently, { userId: impersonate }),
      refreshAnonymous: () => Promise.resolve(),
    }),
    [user, impersonate],
  )

  return (
    <PrincipalProvider value={principal}>
      <PolicyholderManager principal={principal}>{children}</PolicyholderManager>
    </PrincipalProvider>
  )
}

interface ImpersonateManagerProps extends PropsWithChildren {
  user: User
}

const ImpersonateManager: FunctionComponent<ImpersonateManagerProps> = ({
  children,
  user,
}) => {
  const { impersonate } = useImpersonate()

  return impersonate ? (
    <PrincipalManager user={user} impersonate={impersonate}>
      {children}
    </PrincipalManager>
  ) : (
    <AdminImpersonate />
  )
}

export const AdminManager: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [params] = useSearchParams()

  const authenticate = useAuthenticate()
  const user = authenticate.result

  const impersonate = params.get('impersonate')

  return !authenticate.result || authenticate.loading ? (
    <PageLoadingIndicator />
  ) : canAccessImpersonate(user) ? (
    <ImpersonateProvider impersonate={impersonate}>
      <ImpersonateManager user={user}>{children}</ImpersonateManager>
    </ImpersonateProvider>
  ) : (
    <AdminUnauthorised user={user} />
  )
}
