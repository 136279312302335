import { PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren } from 'react'

import { useApiErrorHandlingAsync } from '../../hooks/auth'
import { usePrincipal } from '../authentication'
import { CustomerPaymentProvider } from './CustomerPaymentProvider'
import { CustomerAccountProvider } from './policy/billing/CustomerAccountProvider'
import { PaymentMethodsProvider } from './policy/billing/PaymentMethodsProvider'
import { useProposal } from './proposal/proposalContext'

export const CheckoutManager: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { apiClient } = usePrincipal()
  const { proposal } = useProposal()

  const { result, loading } = useApiErrorHandlingAsync(
    async () =>
      Promise.all([
        apiClient.getCustomerAccounts({ policyNumber: proposal.policyNumber }),
        apiClient.checkoutBind({ proposalCode: proposal.proposalCode }),
        apiClient.getPaymentMethods(),
      ]),
    [proposal],
  )

  if (loading || !result) {
    return <PageLoadingIndicator />
  }

  const [[customerAccount], [customerPayment], paymentMethods] = result

  return (
    <PaymentMethodsProvider paymentMethods={paymentMethods}>
      <CustomerAccountProvider customerAccount={customerAccount}>
        <CustomerPaymentProvider customerPayment={customerPayment}>
          {children}
        </CustomerPaymentProvider>
      </CustomerAccountProvider>
    </PaymentMethodsProvider>
  )
}
