import { css, variant } from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'

export type AccountIconProperty = 'default'

export interface AccountIconProps {
  variant?: AccountIconProperty
}

const defaultProps = withDefaultProps<AccountIconProps>({
  variant: 'default',
})

export const AccountIcon = styled.div.attrs<AccountIconProps>(defaultProps)(
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    height: '2rem',
    borderRadius: '2rem',
    backgroundColor: 'primaryAccent',
    borderColor: 'chalk',
    borderWidth: 1,
  }),
  variant({
    scale: 'AccountIcon',
    variants: {
      default: {},
    },
  }),
)
