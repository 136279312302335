import { AdjustmentInputConfig } from './formBuilder'
import { ProductCondition } from './inputConditions'

export type ManagementConfig = QuoteManagementConfig | AdditionalProfileManagementConfig

export enum ManagementType {
  ADDITIONAL_PROFILE = 'ADDITIONAL_PROFILE',
  POLICYHOLDER = 'POLICYHOLDER',
  PROMOTION = 'PROMOTION',
}

export interface QuoteManagementConfig {
  id: string
  type: undefined
  inputs: AdjustmentInputConfig[]
  conditions?: ProductCondition[]
}

export interface AdditionalProfileManagementConfig {
  id: string
  type: ManagementType.ADDITIONAL_PROFILE
  maxAdditionalProfiles: number
  inputs: AdjustmentInputConfig[]
  conditions: ProductCondition[]
}
