import {
  compose,
  css,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { mdBumps } from '../layout'

export type ListItemProperty = 'default' | 'bullet'

export interface ListItemProps extends LayoutProps, SpaceProps {
  variant?: ListItemProperty
}

const defaultProps = withDefaultProps<ListItemProps>({
  variant: 'default',
})

export const ListItem = styled.li.attrs<ListItemProps>(defaultProps)(
  css({
    listStyleType: 'none',
  }),
  variant({
    scale: 'ListItem',
    variants: {
      default: {},
      bullet: {
        pl: mdBumps,
        position: 'relative',
        '&::before': {
          content: '"•"',
          position: 'absolute',
          left: 0,
        },
      },
    },
  }),
  compose(layout, space),
)
