import {
  color,
  ColorProps,
  compose,
  css,
  layout,
  LayoutProps,
  ResponsiveStyleValue,
  space,
  SpaceProps,
  SystemStyleObject,
  variant,
} from '@wrisk/styled-system'
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { transition, TransitionProps } from '../../transition'
import { Icon } from '../images'
import { Button } from './Button'
import {
  buttonDisabledMapping,
  ButtonDisabledProperty,
  ButtonDisabledProps,
  buttonDisabledVariants,
  defaultButtonStyles,
} from './variants'

export type IconButtonProperty = 'default' | 'primary'

type ButtonProps = Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'color' | 'ref'
>

export interface IconButtonProps
  extends ButtonProps,
    SpaceProps,
    LayoutProps,
    SpaceProps,
    ColorProps,
    TransitionProps {
  variant?: ResponsiveStyleValue<IconButtonProperty>
}

const defaultProps = withDefaultProps<IconButtonProps>({
  variant: 'default',
})

export const IconButton = styled(Button)
  .attrs<ButtonDisabledProps>(buttonDisabledMapping)
  .attrs<IconButtonProps>(defaultProps)(
  defaultButtonStyles,
  css({
    borderRadius: '100%',
    p: 3,
  }),
  variant<SystemStyleObject, ButtonDisabledProperty>({
    prop: '$disabledState',
    scale: 'IconButton.disabled',
    variants: buttonDisabledVariants,
  }),
  variant({
    scale: 'IconButton.variants',
    variants: {
      default: {
        '&:hover': {
          backgroundColor: 'hover',
        },
      },
      primary: {
        backgroundColor: 'primaryAccent',
        [Icon]: {
          'path, ellipse': {
            fill: '#FFF',
          },
        },
        '&:hover:not([disabled])': {
          boxShadow: 'inset 0 0 0 100rem rgba(255,255,255,0.1)',
        },
        '&:active:not([disabled])': {
          boxShadow: 'inset 0 0 0 100rem rgba(255,255,255,0.2)',
        },
      },
    },
  }),
  compose(layout, space, color, transition),
)
