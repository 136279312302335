import { PhoneNumberInput } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { FormInputProps } from '../types'

export const PhoneNumberFormInput: FunctionComponent<FormInputProps<string>> = (
  props,
) => {
  const { value, onChange, tKey, tName, t, validation } = props

  const format = validation?._self?.format as { prefix: string }

  return (
    <PhoneNumberInput
      initialValue={value}
      onChange={onChange}
      placeholder={t(tKey(tName, 'placeholder'))}
      internationalCode={format?.prefix}
    />
  )
}
