import {
  Section,
  SectionContent,
  SectionDescription,
  SectionProps,
  SectionTitle,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getUsablePaymentMethod, PaymentMethodType } from '../../../../../domain'
import {
  TKeyBuilder,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { useCustomerPayment } from '../../../CustomerPaymentProvider'
import { usePaymentMethods } from '../../../policy/billing'
import { useProposal } from '../../proposalContext'
import { PaymentDue } from '../PaymentDue'

export interface PaymentSectionProps extends SectionProps {
  tKey: TKeyBuilder
}

export const PaymentSection: FunctionComponent<PaymentSectionProps> = ({
  tKey,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const { customerPayment } = useCustomerPayment()
  const { paymentMethods } = usePaymentMethods()

  const { proposal } = useProposal()

  const paymentMethod = getUsablePaymentMethod(paymentMethods, PaymentMethodType.CARD)

  const paymentDescription = customerPayment?.amount
    ? t(tKey('payment-due', 'subheader', 'pay'), { defaultValue: null })
    : paymentMethod
    ? t(tKey('payment-due', 'subheader', 'bind'), { defaultValue: null })
    : t(tKey('payment-due', 'subheader', 'setup'), { defaultValue: null })

  return (
    <Section width={1} {...props}>
      <SectionTitle>{t(tKey('payment-due', 'header'))}</SectionTitle>
      {paymentDescription && (
        <SectionDescription>{paymentDescription}</SectionDescription>
      )}
      <SectionContent>
        <PaymentDue payment={customerPayment} proposal={proposal} />
      </SectionContent>
    </Section>
  )
}
