import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  css,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  ResponsiveStyleValue,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { textDecoration, TextDecorationProps } from '../../textDecoration'
import { transition, TransitionProps } from '../../transition'
import { gap, GapProps } from '../containers/gap'
import {
  textTransform,
  TextTransformProps,
  TypoSizeProps,
  typoSizes,
} from '../typography'
import { Anchor, AnchorProps } from './Anchor'

export type FlexAnchorProperty = 'default' | 'highlight' | 'inset'

export interface FlexAnchorProps
  extends AnchorProps,
    BorderProps,
    TypoSizeProps,
    FlexboxProps,
    LayoutProps,
    SpaceProps,
    ColorProps,
    TypographyProps,
    TransitionProps,
    TextDecorationProps,
    TextTransformProps,
    GapProps {
  variant?: ResponsiveStyleValue<FlexAnchorProperty>
}

const defaultProps = withDefaultProps<FlexAnchorProps>({
  variant: 'default',
  typoSize: 'base',
})

export const FlexAnchor = styled(Anchor).attrs<FlexAnchorProps>(defaultProps)(
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 0,
    cursor: 'pointer',
  }),
  variant({
    scale: 'FlexAnchor',
    variants: {
      default: {},
      inset: {
        '&:hover': {
          backgroundColor: 'hover',
          boxShadow: 'inset',
        },
      },
      highlight: {
        '&:hover': {
          backgroundColor: 'hover',
        },
      },
    },
  }),
  typoSizes,
  compose(
    transition,
    typography,
    flexbox,
    layout,
    space,
    border,
    color,
    textDecoration,
    textTransform,
    gap,
  ),
)
