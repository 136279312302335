import {
  css,
  ResponsiveStyleValue,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'

export type StickySidebarProperty = 'default' | 'offset'

export interface StickySidebarProps extends SpaceProps {
  variant?: ResponsiveStyleValue<StickySidebarProperty>
}

const defaultProps = withDefaultProps<StickySidebarProps>({
  variant: 'default',
})

export const StickySidebar = styled.div.attrs<StickySidebarProps>(defaultProps)(
  css({
    position: [undefined, undefined, 'sticky'],
    display: ['none', 'none', 'block'],
    width: '100%',
    top: '4rem',
  }),
  variant({
    scale: 'StickySidebar',
    variants: {
      default: {},
      offset: {
        top: '4rem',
      },
    },
  }),
  space,
)
