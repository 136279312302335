import {
  compose,
  css,
  ResponsiveStyleValue,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { Box, BoxProps } from '../layout/Box'
import { xxlBumps } from '../layout/bumps'
import { Heading, TypoSizeProps, typoSizes } from '../typography'

export type BannerProperty = 'default' | 'alternative'

export interface BannerProps extends SpaceProps {
  variant?: ResponsiveStyleValue<BannerProperty>
}

export const Banner = styled.div.attrs<BannerProps>(
  withDefaultProps<BannerProps>({
    variant: 'default',
  }),
)(
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  variant({
    scale: 'Banner',
    variants: {
      default: {
        my: xxlBumps,
      },
      alternative: {},
    },
  }),
  space,
)

export const BannerTitle: FunctionComponent<BoxProps> = ({ children, ...props }) => (
  <Box width={1} {...props}>
    <Heading typoSize='xxl' as='h1' variant='h1' whiteSpace='pre-line'>
      {children}
    </Heading>
  </Box>
)

export type BannerSubtitleProps = TypoSizeProps & SpaceProps

export const BannerSubtitle = styled.div.attrs<BannerSubtitleProps>(
  withDefaultProps<BannerSubtitleProps>({
    typoSize: 'base',
  }),
)(compose(typoSizes, space))
