import React, { FunctionComponent } from 'react'

import { PaymentMethodType } from '../../../../../domain'
import { useCustomerPayment } from '../../../CustomerPaymentProvider'
import { useCustomerAccount, usePaymentMethods } from '../../../policy/billing'
import { CheckoutBACS } from './CheckoutBACS'
import { CheckoutCard } from './CheckoutCard'

export const UserCheckout: FunctionComponent = () => {
  const { customerAccount } = useCustomerAccount()
  const { paymentMethods } = usePaymentMethods()
  const { customerPayment } = useCustomerPayment()

  switch (customerAccount?.paymentMethodType) {
    case PaymentMethodType.BACS_DEBIT:
      return (
        <CheckoutBACS customerPayment={customerPayment} paymentMethods={paymentMethods} />
      )
    default:
      return (
        <CheckoutCard customerPayment={customerPayment} paymentMethods={paymentMethods} />
      )
  }
}
