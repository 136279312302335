import { ResponsiveValue, system } from '@wrisk/styled-system'
import { Property } from 'csstype'

export interface GapProps {
  gap?: ResponsiveValue<Property.Gap | number>
  rowGap?: ResponsiveValue<Property.Gap | number>
  columnGap?: ResponsiveValue<Property.Gap | number>
}

export const gap = system({
  rowGap: {
    scale: 'space',
    property: 'rowGap',
  },
  columnGap: {
    scale: 'space',
    property: 'columnGap',
  },
  gap: {
    scale: 'space',
    property: 'gap',
  },
})
