import { Box, Flex, FlexAnchor, Icon, mdBumps, Status, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { generatePath, Link } from 'react-router-dom'

import { getPolicyToEndAt, getPolicyVersion } from '../../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { ProductKind } from '../../../../state/configuration'
import { AppPath } from '../../../AppPath'
import {
  CreditNotification,
  OutstandingPaymentNotification,
} from '../../../organisms/billing'
import { usePolicy } from '../../../product/policy/policyContext'
import { PolicyPath } from '../../../product/policy/PolicyPath'
import { useProduct } from '../../../product/productContext'
import { useRefresh } from '../../../product/RefreshProvider'
import { QuoteHeader } from '../../organisms/QuoteHeader'
import { PolicyCardProps } from '../types'

const tKey = TKey('components.view-cancelled-policy')

export const CancelledPolicyCard: FunctionComponent<PolicyCardProps> = ({
  customerAccount,
  ...props
}) => {
  const { product, scheme } = useProduct()
  const { policy } = usePolicy()
  const { refresh } = useRefresh()

  const { t } = useWriskTranslation()

  const { quote } = getPolicyVersion(policy)

  const basePath = generatePath(AppPath.POLICY, {
    productUrlName: scheme.productUrlName,
    policyId: policy.policyId,
  })

  return (
    <Flex width={1} variant='raised' flexWrap='wrap' alignItems='stretch' {...props}>
      <Flex
        alignItems='flex-start'
        width={[1, 1, 3 / 5]}
        borderBottomWidth={[1, 1, 0]}
        borderRightWidth={[0, 0, 1]}
      >
        <QuoteHeader quote={quote} />
      </Flex>
      <Box width={[1, 1, 2 / 5]}>
        <Flex width={1} p={mdBumps}>
          <Typo typoSize='sm' fontWeight='bold'>
            {t(tKey('ended'), {
              endedAt: t(tFormats('date.medium'), { value: getPolicyToEndAt(policy) }),
            })}
          </Typo>
          <Status typoSize='xs' variant='critical' data-testid='policy-status'>
            {t(tKey('status'))}
          </Status>
        </Flex>
        <Box width={1} py={2} borderTopWidth={1}>
          {product?.productKind !== ProductKind.DRIVEAWAY && (
            <FlexAnchor
              as={Link}
              to={basePath}
              width={1}
              variant='inset'
              px={mdBumps}
              py={3}
            >
              <Typo>{t(tKey('actions.billing'))}</Typo>
              <Icon icon='chevronRight' size='tiny' />
            </FlexAnchor>
          )}
          <FlexAnchor
            as={Link}
            to={[basePath, PolicyPath.CLAIMS].join('/')}
            width={1}
            variant='inset'
            px={mdBumps}
            py={3}
          >
            <Typo>{t(tKey('actions.claim'))}</Typo>
            <Icon icon='chevronRight' size='tiny' />
          </FlexAnchor>
        </Box>
      </Box>
      <CreditNotification customerAccount={customerAccount} mb={2} mx={2} type='nested' />
      <OutstandingPaymentNotification
        customerAccount={customerAccount}
        onSettled={refresh}
        type='nested'
        mb={2}
        mx={2}
      />
    </Flex>
  )
}
