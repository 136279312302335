import { Flex, FlexProps, mdBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { isInceptionDate } from '../../../../../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../../../../infrastructure/internationalisation'
import { toDateTime } from '../../../../../../../util/date'
import { usePolicy } from '../../../../policyContext'

const tKey = TKey('components.effective-at')

interface EffectiveAtProps extends FlexProps {
  effectiveAt: string
}

export const EffectiveAt: FunctionComponent<EffectiveAtProps> = ({
  effectiveAt,
  ...props
}) => {
  const { t } = useWriskTranslation()
  const { policy } = usePolicy()

  const display = isInceptionDate(policy, effectiveAt) ? (
    <Typo>
      {t(tFormats('datetime.medium'), {
        value: toDateTime(policy.policyDetail.inceptedAt),
      })}
    </Typo>
  ) : (
    <Typo>{t(tFormats('datetime.medium'), { value: toDateTime(effectiveAt) })}</Typo>
  )

  return (
    <Flex p={mdBumps} borderTopWidth={1} {...props}>
      <Typo fontWeight='bold' mr={2}>
        {t(tKey('date'))}
      </Typo>
      {display}
    </Flex>
  )
}
