import {
  Box,
  Flex,
  FlexProps,
  Heading,
  LinkAnchor,
  mdBumps,
  SectionContent,
  useModal,
} from '@wrisk/ui-components'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { isAddOn, Quote, RiskProfileStatus } from '../../../../../../../domain'
import { useIsMtaAllowed } from '../../../../../../../hooks/adjustments/policy/renewal'
import { useOptionalCovers } from '../../../../../../../hooks/covers'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../../../infrastructure/internationalisation'
import { OptionalFlexAnchor } from '../../../../../../organisms/covers'
import { InRenewalMtaBlockedModal } from '../../../../../../organisms/InRenewalMtaBlockedModal'
import { useProduct } from '../../../../../productContext'
import { ProposalPath } from '../../../../../proposal/ProposalPath'
import { AddOnCard } from './AddOnCard'

interface AddOnSectionProps extends FlexProps {
  quote: Quote
}

const tKey = TKey('components.add-ons')

export const AddOnSection: React.FC<AddOnSectionProps> = ({ quote, ...props }) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()

  const modal = useModal()
  const { product } = useProduct()
  const isMtaAllowed = useIsMtaAllowed()

  const addOns = useOptionalCovers(quote, product).filter(isAddOn(product))

  const addOnsToSelect = addOns.filter(
    (it) => it.ratingStatus === RiskProfileStatus.QUOTE && !it.active,
  )
  const addOnsSelected = addOns.filter((it) => it.active)

  const onUpdateClick = useCallback(
    () =>
      isMtaAllowed
        ? navigate(ProposalPath.ADD_ONS)
        : modal.show({
            content: <InRenewalMtaBlockedModal />,
          }),
    [isMtaAllowed, modal, navigate],
  )

  return addOnsToSelect.length || addOnsSelected.length ? (
    <Flex
      width={1}
      flexDirection='column'
      justifyContent='flex-start'
      rowGap={mdBumps}
      {...props}
    >
      {Boolean(addOnsToSelect.length) && (
        <Box width={1}>
          {addOnsToSelect.map((cover) => (
            <AddOnCard key={cover.coverCode} cover={cover} quote={quote} />
          ))}
        </Box>
      )}

      {Boolean(addOnsSelected.length) && (
        <Box width={1}>
          <Flex width={1}>
            <Heading variant='h3' typoSize='lg'>
              {t(tKey('header'))}
            </Heading>
            <LinkAnchor variant='standalone' typoSize='sm' onClick={onUpdateClick}>
              {t(tKey('update'))}
            </LinkAnchor>
          </Flex>

          <SectionContent>
            <Box width={1} variant='raised' p={2}>
              {addOnsSelected.map((cover) => (
                <OptionalFlexAnchor
                  width={1}
                  key={cover.coverCode}
                  cover={cover}
                  quote={quote}
                />
              ))}
            </Box>
          </SectionContent>
        </Box>
      )}
    </Flex>
  ) : null
}
