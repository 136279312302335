import {
  Box,
  Flex,
  FlexProps,
  Heading,
  Image,
  lgBumps,
  LinkAnchor,
  mdBumps,
  PrimaryButton,
  smBumps,
  Typo,
  WriskTheme,
} from '@wrisk/ui-components'
import { negate } from 'lodash'
import React, { FunctionComponent } from 'react'
import { useTheme } from 'styled-components'

import { hasPolicyEnded, Policy, Proposal } from '../../../domain'
import { useCreateProposalCallback } from '../../../hooks/proposal'
import {
  TKey,
  tProduct,
  useProductTranslation,
} from '../../../infrastructure/internationalisation'
import {
  getFeatureToggles,
  isSchemeHidden,
  Scheme,
  useConfig,
} from '../../../state/configuration'

export interface CreateProposalProps extends FlexProps {
  scheme: Scheme
}

const tKey = TKey('components.create-proposal')

export const CreateProposalSection = ({ scheme, ...props }: CreateProposalProps) => {
  const { t } = useProductTranslation(scheme.schemeCode)

  const theme = useTheme() as WriskTheme

  const onCreateProposalCallback = useCreateProposalCallback(scheme)

  const image = theme.proposalImages[scheme.schemeCode]

  return (
    <Flex
      width={1}
      alignItems='flex-start'
      flexWrap='wrap'
      data-testid={scheme.schemeCode}
      {...props}
    >
      <Box width={[1, 1, 1 / 2]} pr={[0, 0, 8, 10]} pb={[4, 4, 0]}>
        <Image src={image} />
      </Box>
      <Flex width={[1, 1, 1 / 2]} flexDirection='column' alignItems='flex-start'>
        <Box width={1}>
          <Heading variant='h3' typoSize='md'>
            {t(tProduct('name'))}
          </Heading>
          <Typo my={smBumps}>{t(tProduct('description'))}</Typo>
        </Box>

        <Box pb={mdBumps}>
          <LinkAnchor
            variant='standalone'
            typoSize='sm'
            href={t<string>(tProduct('link'))}
          >
            {t(tKey('actions.read-more'))}
          </LinkAnchor>
        </Box>

        <Box width={1}>
          <PrimaryButton
            layout='fixed'
            onClick={onCreateProposalCallback.execute}
            loading={onCreateProposalCallback.loading}
            data-testid='create-proposal'
          >
            {t(tKey('actions.start'))}
          </PrimaryButton>
        </Box>
      </Flex>
    </Flex>
  )
}

export interface CreateProposalsProps extends FlexProps {
  schemes: Scheme[]
  proposals: Proposal[]
  policies: Policy[]
}

export const CreateProposalSections: FunctionComponent<CreateProposalsProps> = ({
  proposals,
  policies,
  schemes,
  ...props
}) => {
  const { multiQuote } = useConfig(getFeatureToggles)

  const activeSchemeCodes = [
    ...policies.filter(negate(hasPolicyEnded)).map((it) => it.schemeCode),
    ...proposals.map((it) => it.schemeCode),
  ]

  const visibleProducts = schemes.filter(negate(isSchemeHidden))

  const displayedProducts = multiQuote
    ? visibleProducts
    : visibleProducts.filter((it) => !activeSchemeCodes.includes(it.schemeCode))

  return displayedProducts.length ? (
    <Box width={1} {...props}>
      {displayedProducts.map((scheme) => (
        <CreateProposalSection key={scheme.schemeCode} scheme={scheme} mb={lgBumps} />
      ))}
    </Box>
  ) : null
}
