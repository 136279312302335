import i18n, { use } from 'i18next'
import { has } from 'lodash'
import { Settings } from 'luxon'
import { initReactI18next } from 'react-i18next'

import { Localisation, StateConfig } from '../../state/configuration'
import { logger } from '../logging'
import { createInternationalisedFormats } from './formats'
import { TranslationResource } from './types'

const defaultLocalisation: Localisation = {
  currency: 'GBP',
  locale: 'en-GB',
  fallbackLanguage: 'en',
  timeZone: 'Europe/London',
}

export const useLocalisationInstance = (
  resources: TranslationResource,
  { contentBaseUrl }: StateConfig,
  localisation?: Partial<Localisation>,
) => {
  const { locale, currency, timeZone, fallbackLanguage }: Localisation = {
    ...defaultLocalisation,
    ...localisation,
  }

  Settings.defaultZone = timeZone
  Settings.defaultLocale = locale

  void use(initReactI18next).init({
    resources,
    fallbackLng: fallbackLanguage,
    lng: locale,
    fallbackNS: 'common',
    missingInterpolationHandler: (text) => {
      logger.error(`i18n: Missing interpolation for "${text}"`)
    },
    missingKeyHandler: (_, __, key, fallbackValue, ___, options) => {
      if (!has(options, 'defaultValue') || key === options.defaultValue) {
        logger.error(`i18n: Missing key ${key} and using fallback ${fallbackValue}`)
      }
    },
    saveMissing: true,
    interpolation: {
      escapeValue: false,
      defaultVariables: {
        contentBaseUrl,
        currency,
        timeZone,
      },
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'li', 'ul'],
    },
  })

  return createInternationalisedFormats(i18n)
}
