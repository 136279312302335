import {
  GlobalStyle,
  ModalProvider,
  shouldForwardProp,
  SnackBarProvider,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { DefaultTheme, StyleSheetManager, ThemeProvider } from 'styled-components'

import { AppRouter } from './application'
import { AuthenticationManager } from './application/authentication'
import { createChatClient } from './application/chat'
import { ProposalTrackingManager } from './application/tracking'
import { BasePage } from './BasePage'
import { createAnonymousClient } from './clients/anonymous'
import { createApiClientFactory } from './clients/api'
import { createAuthClient } from './clients/auth'
import {
  TranslationResource,
  useLocalisationInstance,
} from './infrastructure/internationalisation'
import { createRepository } from './infrastructure/storage'
import { Dependencies, DependenciesProvider } from './state'
import {
  AppConfig,
  ConfigurationProvider,
  ConfigurationState,
  getFeatureToggles,
  mapJsonToConfiguration,
} from './state/configuration'

const sessionRepository = createRepository<string>('anonymousAccessToken', 'session')

export interface AppProps {
  theme: DefaultTheme
  translations: TranslationResource
}

export const App: FunctionComponent<AppProps> = ({ theme, translations }) => {
  const appConfig: AppConfig = { ...CONFIG.app, auth0Domain: CONFIG.auth0.domain }

  const configurationState: ConfigurationState = {
    config: mapJsonToConfiguration(CONFIG.state),
  }
  const i18n = useLocalisationInstance(translations, CONFIG.state, CONFIG.localisation)

  const toggles = getFeatureToggles(configurationState)
  const chatClient = createChatClient(toggles)

  chatClient.register({
    provider: appConfig.provider,
    config: CONFIG.twilio,
    locale: i18n.language,
  })

  const apiClientFactory = createApiClientFactory(CONFIG.apiClient)
  const authClient = createAuthClient(CONFIG.auth0)
  const anonymousClient = createAnonymousClient(CONFIG.sessionClient, sessionRepository)

  const dependencies: Dependencies = {
    apiClientFactory,
    authClient,
    anonymousClient,
    chatClient,
  }

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <GlobalStyle />
          <BrowserRouter>
            <ConfigurationProvider value={configurationState}>
              <DependenciesProvider value={dependencies}>
                <SnackBarProvider>
                  <ModalProvider>
                    <AuthenticationManager>
                      <ModalProvider>
                        <ProposalTrackingManager>
                          <BasePage cookieDomain={appConfig.cookieDomain}>
                            <AppRouter />
                          </BasePage>
                        </ProposalTrackingManager>
                      </ModalProvider>
                    </AuthenticationManager>
                  </ModalProvider>
                </SnackBarProvider>
              </DependenciesProvider>
            </ConfigurationProvider>
          </BrowserRouter>
        </StyleSheetManager>
      </ThemeProvider>
    </I18nextProvider>
  )
}
