import { PrimaryButton, xxlBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  CustomerPayment,
  getUsablePaymentMethod,
  PaymentMethod,
  PaymentMethodType,
} from '../../../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../../../../hooks/auth'
import { useCheckoutStripe } from '../../../../../hooks/stripe'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { usePrincipal } from '../../../../authentication'
import { FullPage } from '../../../../templates'
import { useProposal } from '../../proposalContext'
import { CheckoutPath } from '../CheckoutPath'
import { MandateSection } from '../sections/MandateSection'
import { PaymentSection } from '../sections/PaymentSection'

interface CheckoutBACSProps {
  customerPayment: CustomerPayment | undefined
  paymentMethods: PaymentMethod[]
}

const tKey = TKey('pages.user-checkout-direct-debit')

export const CheckoutBACS: FunctionComponent<CheckoutBACSProps> = ({
  customerPayment,
  paymentMethods,
}) => {
  const { t } = useWriskTranslation()

  const { apiClient } = usePrincipal()

  const { proposal, setProposal } = useProposal()

  const paymentMethod = getUsablePaymentMethod(
    paymentMethods,
    PaymentMethodType.BACS_DEBIT,
  )

  const onPayment = useCheckoutStripe({
    successPath: [location.pathname, CheckoutPath.PAYMENT_RESULT_CALLBACK].join('/'),
    cancelPath: location.pathname,
  })

  const onCheckout = useApiErrorHandlingAsyncCallback(async () =>
    customerPayment?.amount
      ? await onPayment.execute(customerPayment.paymentId)
      : setProposal(await apiClient.bindProposal(proposal.proposalCode)),
  )

  const buttonDescription = customerPayment?.amount
    ? t(tKey('actions', 'pay'))
    : t(tKey('actions', 'bind'))

  return (
    <FullPage pageId='checkout-page' header={t(tKey('header'))}>
      <MandateSection mb={xxlBumps} />
      <PaymentSection tKey={tKey} mb={xxlBumps} />

      <PrimaryButton
        data-testid='bind-proposal'
        layout='fixed'
        onClick={onCheckout.execute}
        loading={onCheckout.loading}
        disabled={!paymentMethod}
      >
        {buttonDescription}
      </PrimaryButton>
    </FullPage>
  )
}
