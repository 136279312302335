import { loadStripe } from '@stripe/stripe-js'
import { useModal, WriskTheme } from '@wrisk/ui-components'
import React, { useCallback } from 'react'
import { useTheme } from 'styled-components'

import {
  UnauthorizedModalContent,
  useAdminUnauthorised,
  usePrincipal,
} from '../application/authentication'
import {
  AdminSettleModalContent,
  AdminSettleModalFormProps,
} from '../application/organisms/admin'
import { usePolicy } from '../application/product/policy/policyContext'
import { useProposal } from '../application/product/proposal/proposalContext'
import { getPolicyholder, getPolicyVersion } from '../domain'
import { TKey, useWriskTranslation } from '../infrastructure/internationalisation'
import { pathWithOrigin } from '../util/dom'
import { useApiErrorHandlingAsyncCallback } from './auth'

const tKey = TKey('components.pay-now')

const {
  stripe: { apiKey, stripeAccount },
} = CONFIG

interface StripePayment {
  successPath: string
  cancelPath: string
}

interface StripeSettle extends StripePayment {
  accountId: string
  onSuccess?: () => void
}

interface StripeCheckout extends StripePayment {
  paymentId: string
  onSuccess?: () => void
}

interface StripeSetup extends StripePayment {
  clientReference?: string
}

export const useSettleStripe = ({
  accountId,
  successPath,
  cancelPath,
  onSuccess,
}: StripeSettle) => {
  const { show } = useModal()
  const { i18n, t } = useWriskTranslation()
  const theme = useTheme() as WriskTheme
  const { apiClient, isAdmin } = usePrincipal()
  const { policy } = usePolicy()
  const { quote } = getPolicyVersion(policy)
  const {
    data: { dateOfBirth },
  } = getPolicyholder(quote)

  const invokeApi = useCallback(
    async (props: AdminSettleModalFormProps) =>
      await apiClient.stripeMotoSettleSession({
        accountId,
        country: new Intl.Locale(i18n.language).region ?? i18n.language,
        itemName: t(tKey('line-item.name')),
        ...props,
      }),
    [accountId, apiClient, i18n.language, t],
  )

  return useApiErrorHandlingAsyncCallback(async () => {
    if (isAdmin) {
      show({
        content: (
          <AdminSettleModalContent
            invokeApi={invokeApi}
            dateOfBirth={dateOfBirth}
            onSuccess={onSuccess}
          />
        ),
      })
    } else {
      const stripe = await loadStripe(apiKey, { stripeAccount })

      await stripe?.redirectToCheckout(
        await apiClient.stripeSettleSession({
          accountId,
          cancelUrl: pathWithOrigin(cancelPath),
          successUrl: pathWithOrigin(successPath),
          lineItem: {
            description: t<string>(tKey('line-item.outstanding-description')),
            name: t(tKey('line-item.name')),
            imageUrl: theme.illus.lineItem
              ? pathWithOrigin(theme.illus.lineItem)
              : undefined,
          },
        }),
      )
    }
  })
}

export const useSetupStripe = ({
  successPath,
  cancelPath,
  clientReference,
}: StripeSetup) => {
  const { apiClient } = usePrincipal()

  const adminUnauthorised = useAdminUnauthorised()

  return useApiErrorHandlingAsyncCallback(
    adminUnauthorised(async () => {
      const stripe = await loadStripe(apiKey, { stripeAccount })

      await stripe?.redirectToCheckout(
        await apiClient.stripeSetupSession({
          clientReference,
          cancelUrl: pathWithOrigin(cancelPath),
          successUrl: pathWithOrigin(successPath),
        }),
      )
    }),
  )
}

export const useCheckoutStripe = ({ successPath, cancelPath }: StripePayment) => {
  const { t, i18n } = useWriskTranslation()
  const theme = useTheme() as WriskTheme

  const { apiClient } = usePrincipal()

  const adminUnauthorised = useAdminUnauthorised()

  return useApiErrorHandlingAsyncCallback(
    adminUnauthorised(async (paymentId: string) => {
      const stripe = await loadStripe(apiKey, { stripeAccount })

      await stripe?.redirectToCheckout(
        await apiClient.stripeCheckoutSession({
          paymentId,
          cancelUrl: pathWithOrigin(cancelPath),
          successUrl: pathWithOrigin(successPath),
          lineItem: {
            description: t<string>(tKey('line-item.description')),
            name: t(tKey('line-item.name')),
            imageUrl: theme.illus.lineItem
              ? pathWithOrigin(theme.illus.lineItem)
              : undefined,
          },
          locale: i18n.language,
        }),
      )
    }),
  )
}

export const useAdminCheckoutStripe = ({ paymentId, onSuccess }: StripeCheckout) => {
  const { show } = useModal()
  const { i18n, t } = useWriskTranslation()
  const { apiClient, isAdmin } = usePrincipal()
  const {
    proposal: { quote },
  } = useProposal()
  const {
    data: { dateOfBirth },
  } = getPolicyholder(quote)

  const invokeApi = useCallback(
    async (props: AdminSettleModalFormProps) =>
      await apiClient.stripeMotoCheckoutSession({
        paymentId,
        country: new Intl.Locale(i18n.language).region ?? i18n.language,
        itemName: t(tKey('line-item.name')),
        ...props,
      }),
    [paymentId, apiClient, i18n.language, t],
  )

  return useApiErrorHandlingAsyncCallback(async () => {
    show({
      content: isAdmin ? (
        <AdminSettleModalContent
          invokeApi={invokeApi}
          dateOfBirth={dateOfBirth}
          onSuccess={onSuccess}
        />
      ) : (
        <UnauthorizedModalContent />
      ),
    })
  })
}
