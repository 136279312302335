import {
  color,
  ColorProps,
  compose,
  css,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { xxlBumps } from '../layout'

export type PageContainerProperty = 'default'

export interface PageContainerProps extends ColorProps, SpaceProps {
  variant?: PageContainerProperty
}

const defaultProps = withDefaultProps<PageContainerProps>({
  variant: 'default',
})

export const PageContainer = styled.section.attrs<PageContainerProps>(defaultProps)(
  css({
    flex: '1 0 auto',
    pb: xxlBumps,
  }),
  variant({
    scale: 'PageContainer',
    variants: {
      default: {},
    },
  }),
  compose(color, space),
)
