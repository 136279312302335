import { DateTime, Duration } from 'luxon'

import { getStartOfDay } from '../../../../util/date'
import { required } from '../helpers'
import { ValidationFunctions } from '../types'

export const date: ValidationFunctions = {
  _self: {
    required,
    afterOrEqual: (option: string) => (value: string) =>
      DateTime.fromISO(value) >= getStartOfDay().plus(Duration.fromISO(option)),
    beforeOrEqual: (option: string) => (value: string) =>
      DateTime.fromISO(value) <= getStartOfDay().plus(Duration.fromISO(option)),
  },
  date: {
    afterOrEqual:
      (option: string, formKey: string) => (value: string, formValues: string) =>
        DateTime.fromISO(formValues[formKey]) >=
        DateTime.fromISO(value).plus(Duration.fromISO(option)),
    beforeOrEqual:
      (option: string, formKey: string) => (value: string, formValues: string) =>
        DateTime.fromISO(formValues[formKey]) <=
        DateTime.fromISO(value).plus(Duration.fromISO(option)),
  },
}
