import { variant } from '@wrisk/styled-system'

export type TypoSizeProperty = 'xxxl' | 'xxl' | 'xl' | 'lg' | 'md' | 'base' | 'sm' | 'xs'

export interface TypoSizeProps {
  typoSize?: TypoSizeProperty
}

export const typoSizes = variant({
  prop: 'typoSize',
  scale: 'typoSizes',
  variants: {
    xxxl: {},
    xxl: {},
    xl: {},
    lg: {},
    md: {},
    base: {},
    sm: {},
    xs: {},
  },
})
