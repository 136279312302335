import {
  airbrakeCritical,
  airbrakeDebug,
  airbrakeError,
  airbrakeWarning,
} from '../airbrake'

const LOG_LEVEL_FROM_CONFIG: string = CONFIG.logLevel ?? 'silent'

/**
 * Supported "levels of logging" by names
 * The array index is "level of logging" as number
 */
export const LOG_LEVEL_NAMES = [
  'trace',
  'debug',
  'info',
  'warn',
  'error',
  'critical',
  'silent',
]

/**
 * Logger class to use instead of direct console.xxx() calls
 * The level of logging is configured by CONFIG.logLevel value, 'silent' by default
 * @class Logger
 */
export class Logger {
  private readonly _level: number
  private readonly _name: string

  constructor(name: string) {
    this._name = name
    this._level = Logger.levelToNumber(name)
  }

  /**
   * Returns current "level of logging" as number
   */
  get level() {
    return this._level
  }

  /**
   * Returns current "level of logging" as string
   */
  get name() {
    return this._name
  }

  /**
   * Converts a string "level of logging"  ('trace', 'debug', 'info' and so on) into a number value
   * @param {string} [logLevelAsString] text to convert, if not specified the LOG_LEVEL_FROM_CONFIG value is used
   * @returns {number} log level index as number or -1
   */
  public static levelToNumber(logLevelAsString: string = LOG_LEVEL_FROM_CONFIG): number {
    return LOG_LEVEL_NAMES.indexOf(logLevelAsString.toLowerCase())
  }

  /**
   * Main logging method
   * @param {string} logLevel - the logging level as a string ('trace', 'debug', 'info' and so on)
   * @param {Array<any>} [args] - optional parameters ot output in the console
   */
  public notify(logLevel: string, ...args): void {
    const level = Logger.levelToNumber(logLevel)
    if (level < this._level) {
      return // Do nothing, given logging level is too low
    }
    switch (level) {
      case 0: // 'trace':
        console.trace(...args)
        break
      case 1: // 'debug':
        console.debug(...args)
        airbrakeDebug(args?.[0], { params: { ...args } })
        break
      case 2: // 'info':
        console.info(...args)
        break
      case 3: // 'warn':
        console.warn(...args)
        airbrakeWarning(args?.[0])
        break
      case 4: // 'error':
        console.error(...args)
        airbrakeError(args?.[0])
        break
      case 5: // 'critical':
        console.error(...args)
        airbrakeCritical(args?.[0])
        break
      default:
        // Do nothing
        break
    }
  }

  public trace(...args): void {
    this.notify(LOG_LEVEL_NAMES[0], ...args)
  }

  public debug(...args): void {
    this.notify(LOG_LEVEL_NAMES[1], ...args)
  }

  public info(...args): void {
    this.notify(LOG_LEVEL_NAMES[2], ...args)
  }

  public warn(...args): void {
    this.notify(LOG_LEVEL_NAMES[3], ...args)
  }

  public error(...args): void {
    this.notify(LOG_LEVEL_NAMES[4], ...args)
  }

  public critical(...args): void {
    this.notify(LOG_LEVEL_NAMES[5], ...args)
  }

  public log(...args): void {
    this.notify(this._name, ...args)
  }
}

/**
 * Pre-configured instance of the Logger
 * Usage:
 * log.info('Text')
 * log.warn('Message')
 * log.error('Text', 123, ...)
 * ...
 */
export const logger = new Logger(LOG_LEVEL_FROM_CONFIG)
