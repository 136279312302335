import { isEmpty, rangeRight } from 'lodash'
import { DateTime, Info } from 'luxon'
import React, { ChangeEvent, FunctionComponent, useCallback, useMemo } from 'react'

import { Box, Flex, TimeInput } from '../atoms'
import { useNotifyingState } from '../extensions'
import { SelectInput } from './SelectInput'

export interface DateTimeInputProps {
  earliestYear: number
  latestYear: number | undefined
  onChange: (value: string | null) => void
  value: string | undefined
  hideTime?: boolean
}

interface DateTimeProps {
  year?: number
  month?: number
  day?: number
  hour?: number
  minute?: number
}

const flexItemWidth = 'calc(50% - 0.25rem)'

const fromIsoToDateTimeObject = (value: string | null) => {
  if (!value) return {}
  const { year, month, day, hour, minute } = DateTime.fromISO(value)
  return { year, month, day, hour, minute }
}

const fromDateTimeObjectToIso = (value: DateTimeProps) => {
  if (!value.year || !value.month || !value.day) return null
  return DateTime.fromObject(value).toISO()
}

const convertTimeString = (
  timeString: string | undefined,
): Pick<DateTimeProps, 'hour' | 'minute'> => {
  const time = isEmpty(timeString) ? '00:00' : (timeString as string)
  const dt = DateTime.fromFormat(time, 'HH:mm')
  return {
    hour: dt.hour,
    minute: dt.minute,
  }
}

const formatTimeFromComponents = (dateTimeObject: DateTimeProps): string => {
  const { hour, minute } = dateTimeObject
  const dt = DateTime.fromObject({ hour: hour ?? 0, minute: minute ?? 0 })
  return dt.toFormat('HH:mm')
}

export const DateTimeInput: FunctionComponent<DateTimeInputProps> = ({
  value,
  onChange,
  earliestYear,
  latestYear,
  hideTime,
}) => {
  const [dateTimeObject, setDateTimeObject] = useNotifyingState<DateTimeProps>(
    fromIsoToDateTimeObject(value ?? DateTime.local().startOf('day').toISO()),
    [(it) => onChange(fromDateTimeObjectToIso(it))],
  )

  const { days, months, years } = useMemo(() => {
    const days = Array.from({ length: 31 }, (_, i) => i + 1).map((it, index) => ({
      value: index + 1,
      text: String(it),
    }))

    const months = Info.months().map((it, index) => ({
      value: index + 1,
      text: it.substring(0, 3), // Jan, Feb, Mar, ...
    }))

    const years = rangeRight(earliestYear, (latestYear ?? DateTime.local().year) + 1).map(
      (it) => ({
        value: it,
        text: it.toString(),
      }),
    )

    return { days, months, years }
  }, [earliestYear, latestYear])

  const onDayChange = useCallback(
    (value: number | undefined) =>
      setDateTimeObject({
        ...dateTimeObject,
        day: value,
      }),
    [setDateTimeObject, dateTimeObject],
  )

  const onMonthChange = useCallback(
    (value: number | undefined) =>
      setDateTimeObject({
        ...dateTimeObject,
        month: value,
      }),
    [setDateTimeObject, dateTimeObject],
  )

  const onYearChange = useCallback(
    (value: number | undefined) =>
      setDateTimeObject({
        ...dateTimeObject,
        year: value,
      }),
    [setDateTimeObject, dateTimeObject],
  )

  const onTimeChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
      setDateTimeObject({
        ...dateTimeObject,
        ...convertTimeString(value),
      }),
    [setDateTimeObject, dateTimeObject],
  )

  return (
    <Flex width={1} gap={2} justifyContent='flex-start' flexWrap='wrap'>
      <Box width={[flexItemWidth, '100px']}>
        <SelectInput<number>
          values={days}
          value={dateTimeObject.day}
          placeholder='Day'
          onChange={onDayChange}
        />
      </Box>
      <Box width={[flexItemWidth, '125px']}>
        <SelectInput<number>
          values={months}
          value={dateTimeObject.month}
          placeholder='Month'
          onChange={onMonthChange}
        />
      </Box>
      <Box width={[flexItemWidth, '110px']}>
        <SelectInput<number>
          values={years}
          value={dateTimeObject.year}
          placeholder='Year'
          onChange={onYearChange}
        />
      </Box>
      {!hideTime && (
        <Box width={[flexItemWidth, '97px']}>
          <TimeInput
            value={formatTimeFromComponents(dateTimeObject)}
            onChange={onTimeChange}
            textAlign='center'
          />
        </Box>
      )}
    </Flex>
  )
}
