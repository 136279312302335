import { DateTime, Duration } from 'luxon'

import { required } from '../helpers'
import { ValidationFunctions } from '../types'

export const radio: ValidationFunctions = {
  _self: {
    required,
  },
  date: {
    beforeOrEqualPlusYears:
      (option: string, formKey: string) => (value: number, formValues: string) => {
        return (
          DateTime.fromISO(formValues[formKey]).plus({ years: value }) <=
          DateTime.local().plus(Duration.fromISO(option))
        )
      },
  },
}
