import { createContext, useContext } from 'react'

import { ConfigurationState } from './config'

const ConfigurationContext = createContext<ConfigurationState | undefined>(undefined)

export const ConfigurationProvider = ConfigurationContext.Provider

export const useConfig = <T>(configCallback: (config: ConfigurationState) => T) => {
  const context = useContext(ConfigurationContext)

  if (!context) {
    throw new Error('Cannot use ConfigurationContext when undefined')
  }

  return configCallback(context)
}
