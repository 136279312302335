import { isNil, negate } from 'lodash'

import { VehicleInputValue } from '../../inputs'
import { ValidationFunctions } from '../types'

export const vehicleTabs: ValidationFunctions = {
  _self: {
    required: () => (value: VehicleInputValue | null | undefined) =>
      !isNil(value?.specification) && !isNil(value.regLookup),
    vrn: (isRequired: boolean) => (value: VehicleInputValue | null | undefined) =>
      !isRequired || negate(isNil)(value?.vrn),
  },
}
