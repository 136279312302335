import {
  Box,
  Form,
  mdBumps,
  Notification,
  Section,
  SectionContent,
  SectionTitle,
  TextArea,
  Typo,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useErrorHandlingAsyncCallback } from '../../../../hooks/errors'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { Page, useUpButton } from '../../../../infrastructure/routing'
import { usePrincipal } from '../../../authentication'
import { ActionBar } from '../../../organisms/form'
import { FileUpload } from '../../../organisms/upload'
import { FullPage } from '../../../templates'
import { usePolicy } from '../policyContext'

export interface UploadPageProps {
  type: string
  config: Partial<{
    maxSize?: number
    maxFiles?: number
    accept?: { [key: string]: string[] }
  }>
  parent: Page
}

export interface GuidanceTranslation {
  header: string
  instructions: string[]
}

const tKey = TKey('pages.upload')

interface UploadFormData {
  files: Array<{ path: string; name: string }>
  comment: string
}

export const UploadPage: FunctionComponent<UploadPageProps> = ({
  type,
  config,
  parent,
}) => {
  const { apiClient } = usePrincipal()
  const { policy } = usePolicy()

  const navigate = useNavigate()
  const upButton = useUpButton(parent)

  const { t } = useWriskTranslation()

  const guidance = t(tKey(type, 'guidance'), {
    defaultValue: null,
    returnObjects: true,
  }) as GuidanceTranslation[]

  const onSubmit = useErrorHandlingAsyncCallback(async (data: UploadFormData) => {
    await apiClient.commitUpload(policy.policyId, {
      fileNames: data.files.map((it) => it.name),
      comment: data.comment,
      purpose: type,
    })
    navigate('../confirmation')
  })

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<UploadFormData>({
    defaultValues: {
      files: [],
      comment: '',
    },
  })

  return (
    <FullPage header={t(tKey(type, 'header'))} upButton={upButton}>
      {guidance?.length && (
        <Notification mb={xxlBumps} width={1} type='standalone' display='block'>
          {guidance.map(({ header, instructions }, index) => (
            <Box key={index} mb={mdBumps}>
              <Typo fontWeight='bold' mb={1}>
                {header}
              </Typo>
              <Box as='ul' ml={4}>
                {instructions.map((instruction, index) => (
                  <Typo key={index} as='li' style={{ listStyleType: 'disc' }}>
                    {instruction}
                  </Typo>
                ))}
              </Box>
            </Box>
          ))}
        </Notification>
      )}

      <Form data-testid='uploads' onSubmit={handleSubmit(onSubmit.execute)}>
        <Section width={1} mb={xxlBumps}>
          <SectionTitle>{t(tKey(type, 'titles', 'documents'))}</SectionTitle>
          <SectionContent>
            <Controller
              name='files'
              control={control}
              rules={{ required: true, validate: (value) => Boolean(value.length) }}
              render={({ field: { onChange } }) => (
                <FileUpload
                  reference={policy.policyId}
                  onChange={onChange}
                  maxFiles={config.maxFiles}
                  maxSize={config.maxSize}
                  accept={config.accept}
                />
              )}
            />
          </SectionContent>
        </Section>
        <Section width={1} mb={xxlBumps}>
          <SectionTitle>{t(tKey(type, 'titles', 'comments'))}</SectionTitle>
          <SectionContent>
            <Controller
              name='comment'
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextArea
                  width={1}
                  minHeight='128px'
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder={t<string>(tKey(type, 'comment', 'placeholder'))}
                />
              )}
            />
          </SectionContent>
        </Section>
        <ActionBar
          tKey={TExtends(tKey, type)}
          disabled={!isValid}
          loading={onSubmit.loading}
        />
      </Form>
    </FullPage>
  )
}
