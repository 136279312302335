import { useMemo } from 'react'

import { usePrincipal } from '../../../../application/authentication'
import { usePolicy } from '../../../../application/product/policy/policyContext'
import { Data, PolicyAdjustmentResponse } from '../../../../domain'
import { AdjustmentInputConfig } from '../../../../state/configuration'
import { useApiErrorHandlingAsyncCallback } from '../../../auth'
import { hasChanges } from '../helpers'
import { toPolicyAdjustment } from './adjustments'
import { toPolicyData } from './data'

export const usePolicyFormData = (
  inputs: AdjustmentInputConfig[],
  hidden: AdjustmentInputConfig[] = [],
) => {
  const { policy } = usePolicy()

  return useMemo(() => {
    const toData = toPolicyData(policy.currentPolicyVersion)

    return [...inputs, ...hidden].reduce(
      (data, input) => ({
        ...data,
        [input.name]: toData(input.adjustment),
      }),
      {},
    )
  }, [inputs, hidden, policy])
}

export const usePolicyAdjustment = (
  inputs: AdjustmentInputConfig[],
  onSubmitted?: (response?: PolicyAdjustmentResponse) => void,
) => {
  const existingData = usePolicyFormData(inputs)

  const { apiClient } = usePrincipal()
  const { policy } = usePolicy()

  return useApiErrorHandlingAsyncCallback(async (data: Data, effectiveAt?: string) => {
    const policyAdjustment = toPolicyAdjustment(inputs, policy)(
      existingData,
      data,
      effectiveAt,
    )

    if (hasChanges(policyAdjustment)) {
      const response = await apiClient.createPolicyAdjustment(policyAdjustment)
      onSubmitted?.(response)
    } else {
      onSubmitted?.()
    }
  })
}
