import { Name } from '../../../../domain'
import { required } from '../helpers'
import { ValidationFunctions } from '../types'

export const name: ValidationFunctions = {
  _self: {
    required,
    firstNameMinLength: (minLength: number) => (value: Name) =>
      value.firstName.length >= minLength,
    firstNameMaxLength: (maxLength: number) => (value: Name) =>
      value.firstName.length <= maxLength,
    lastNameMinLength: (minLength: number) => (value: Name) =>
      value.lastName.length >= minLength,
    lastNameMaxLength: (maxLength: number) => (value: Name) =>
      value.lastName.length <= maxLength,
  },
}
