import { omit } from 'lodash'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDeepCompareEffect } from 'use-deep-compare'

import { ConfiguredInput } from '../configuredInputs'

const toValidationTriggers = (configuredInputs: ConfiguredInput[]): string[] =>
  configuredInputs.flatMap((it) => {
    const otherRules = omit(it.input.validation ?? {}, '_self')
    return Object.keys(otherRules)
  })

export const useValidationTriggers = (configuredInputs: ConfiguredInput[]) => {
  const {
    control,
    trigger,
    formState: { isValidating },
  } = useFormContext()

  const triggers = toValidationTriggers(configuredInputs)

  const values = useWatch({
    control,
    name: triggers,
  })

  useDeepCompareEffect(() => {
    if (isValidating) {
      void trigger()
    }
  }, values)
}
