import {
  border,
  BorderProps,
  compose,
  css,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../defaultProps'
import { transition, TransitionProps } from '../transition'

export type SelectResultsProperty = 'default'

export interface SelectResultsProps
  extends LayoutProps,
    SpaceProps,
    BorderProps,
    TransitionProps {
  variant?: SelectResultsProperty
  role?: string
}

const defaultProps = withDefaultProps<SelectResultsProps>({
  variant: 'default',
  role: 'listbox',
})

export const SelectResults = styled.ul.attrs<SelectResultsProps>(defaultProps)(
  css({
    position: 'relative',
    width: '100%',
    overflowY: 'auto',
    borderRadius: 2,
    maxHeight: '14rem',
    zIndex: 150,
    fontFamily: 'input',
    backgroundColor: 'chalk',
    boxShadow: 'default',
    p: 2,
  }),
  variant({
    scale: 'SelectResults',
    variants: {
      default: {},
    },
  }),
  compose(layout, space, border, transition),
)
