import { PrimaryButton } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { CustomerPayment, PaymentMethod } from '../../../../../domain'
import { useAdminCheckoutStripe } from '../../../../../hooks/stripe'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { useCurrentPath } from '../../../../../infrastructure/routing'
import { useProposal } from '../../proposalContext'
import { CheckoutDirectDebitContent } from './CheckoutDirectDebitContent'

interface CheckoutDirectDebitWithCardTabProps {
  paymentMethod: PaymentMethod | undefined
  customerPayment: CustomerPayment
}

const tKey = TKey('components.admin-checkout-direct-debit-with-card')

export const CheckoutDirectDebitWithCardTab: FunctionComponent<
  CheckoutDirectDebitWithCardTabProps
> = ({ paymentMethod, customerPayment }) => {
  const { t } = useWriskTranslation()
  const path = useCurrentPath()

  const { refresh } = useProposal()

  const onStripePayment = useAdminCheckoutStripe({
    paymentId: customerPayment.paymentId,
    successPath: path,
    cancelPath: path,
    onSuccess: refresh,
  })

  const buttonDescription = t(tKey('actions', 'pay'))

  return (
    <CheckoutDirectDebitContent tKey={tKey}>
      <PrimaryButton
        data-testid='continue-payment'
        layout='fixed'
        onClick={onStripePayment.execute}
        loading={onStripePayment.loading}
        disabled={!paymentMethod}
      >
        {buttonDescription}
      </PrimaryButton>
    </CheckoutDirectDebitContent>
  )
}
