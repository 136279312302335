import {
  compose,
  css,
  ResponsiveStyleValue,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'

export type StickyHeaderContainerProperty = 'default' | 'mobile'

export interface StickyHeaderContainerProps extends SpaceProps {
  variant?: ResponsiveStyleValue<StickyHeaderContainerProperty>
}

const defaultProps = withDefaultProps<StickyHeaderContainerProps>({
  variant: 'default',
})

export const StickyHeaderContainer = styled.div.attrs<StickyHeaderContainerProps>(
  defaultProps,
)(
  css({
    position: 'sticky',
    width: '100%',
    top: 0,
    zIndex: 998,
  }),
  variant({
    variants: {
      default: {
        display: ['none', 'block'],
      },
      mobile: {
        display: ['block', 'none'],
      },
    },
  }),
  compose(space),
)
