import { AxiosInstance } from 'axios'

import { StripeSession } from '../../../domain'
import { withGenericErrorHandling } from '../errors'

export interface PaymentCardClient {
  stripeCheckoutSession: (params: StripeCheckoutRequest) => Promise<StripeSession>
  stripeMotoCheckoutSession: (params: StripeMotoCheckoutRequest) => Promise<void>
  stripeMotoSettleSession: (params: StripeMotoSettleRequest) => Promise<void>
  stripeSettleSession: (params: StripeSettleRequest) => Promise<StripeSession>
  stripeSetupSession: (params: StripeSetupRequest) => Promise<StripeSession>
}

interface LineItem {
  name: string
  description?: string
  imageUrl: string | undefined
}

export interface StripeCheckoutRequest {
  paymentId: string
  successUrl: string
  cancelUrl: string
  lineItem: LineItem
  locale: string
}

interface StripeMotoBaseProps {
  token: string
  postalCode: string
  country: string // https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
  itemName: string
}

export interface StripeMotoCheckoutRequest extends StripeMotoBaseProps {
  paymentId: string
}

export interface StripeMotoSettleRequest extends StripeMotoBaseProps {
  accountId: string
}

export interface StripeMotoSettleError {
  type: 'timeout' | 'card_error' | 'invalid_request_error' | 'incomplete'
  chargeStatus?: string
  cardDeclineCode?: string
  message?: string
}

export interface StripeSettleRequest {
  accountId: string
  successUrl: string
  cancelUrl: string
  lineItem: LineItem
}

export interface StripeSetupRequest {
  clientReference?: string
  successUrl: string
  cancelUrl: string
}

export const createPaymentCardClient = (
  getInstance: () => Promise<AxiosInstance>,
): PaymentCardClient => ({
  stripeCheckoutSession: (params) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<StripeSession>('/stripe/checkout', params)
      return response.data
    }),
  stripeMotoCheckoutSession: (params) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      await instance.post<StripeSession>('/stripe/moto/checkout', params)
    }),
  stripeMotoSettleSession: (params) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      await instance.post<StripeSession>('/stripe/moto/settle', params)
    }),
  stripeSettleSession: (params) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<StripeSession>('/stripe/settle', params)
      return response.data
    }),
  stripeSetupSession: (params) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<StripeSession>('/stripe/setup', params)
      return response.data
    }),
})
