import { FlexAnchor, FlexAnchorProps, mdBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

export const MenuAnchor: FunctionComponent<FlexAnchorProps> = ({
  children,
  // eslint-disable-next-line unused-imports/no-unused-vars
  ref,
  ...props
}) => (
  <FlexAnchor
    flexDirection='column'
    alignItems='flex-start'
    width={1}
    px={mdBumps}
    py={3}
    variant='inset'
    {...props}
  >
    {children}
  </FlexAnchor>
)
