import { useProposalFormData } from '../../../../hooks/adjustments/proposal'
import { useProductConditions } from '../../conditions'
import { useProduct } from '../../productContext'

export const useProposalConfirmation = () => {
  const {
    product: { proposalConfirmation },
  } = useProduct()

  const data = useProposalFormData(proposalConfirmation?.inputs ?? [])

  const conditions = useProductConditions(data)

  return {
    isNotConfirmed: proposalConfirmation?.conditions.every(conditions) ?? false,
    data: data,
  }
}
