import {
  AnimatedPage,
  Banner,
  BannerSubtitle,
  BannerTitle,
  WideContainer,
  xlBumps,
  xxsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import {
  matchPath,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useRoutes,
} from 'react-router-dom'

import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { useCurrentPath } from '../../../../../infrastructure/routing'
import { Footer } from '../../../../templates'
import { useProduct } from '../../../productContext'
import { usePolicy } from '../../policyContext'
import { TabHeader } from './components/TabHeader'
import { TabSelect } from './components/TabSelect'
import { useTabs } from './hooks'
import { TabItem } from './types'

const tKey = TKey('pages.policy-configuration')

const Content: FunctionComponent<{ tabs: TabItem[] }> = ({ tabs }) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()
  const location = useLocation()
  const path = useCurrentPath()

  const currentTab = useMemo(
    () =>
      tabs.find((tab, index) => {
        const pathname = index ? [path, tab.path].join('/') : path

        return Boolean(matchPath(pathname, location.pathname))
      }) ?? tabs[0],
    [tabs, path, location],
  )

  const onClick = useCallback(
    (tab: TabItem) => navigate(['..', tab.path].join('/')),
    [navigate],
  )

  return (
    <AnimatedPage pageId='policy-configuration-page' footer={<Footer />}>
      <Banner>
        <WideContainer flexDirection='column'>
          <BannerSubtitle mb={xxsBumps}>{t(tKey('subheader'))}</BannerSubtitle>
          <BannerTitle>{t(tKey('header'))}</BannerTitle>
        </WideContainer>
      </Banner>

      <WideContainer mb={xlBumps}>
        <TabHeader
          displayOn='desktop'
          tabs={tabs}
          current={currentTab}
          onClick={onClick}
        />
        <TabSelect
          displayOn='mobile'
          tabs={tabs}
          current={currentTab}
          onClick={onClick}
        />
      </WideContainer>

      <Outlet />
    </AnimatedPage>
  )
}

export const PolicyTabs: FunctionComponent = () => {
  const { policy } = usePolicy()
  const { product } = useProduct()

  const tabs = useTabs(policy, product)

  const routes = tabs
    .map(({ TabPage, path, id }, index) => ({
      id,
      index: index === 0,
      path: index === 0 ? undefined : path,
      element: <TabPage basePath='/' />,
    }))
    .concat({
      id: 'root',
      index: false,
      path: '*',
      element: <Navigate to='.' replace={true} />,
    })

  return useRoutes([
    {
      element: <Content tabs={tabs} />,
      children: routes,
    },
  ])
}
