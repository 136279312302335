import { ResponsiveValue, system } from '@wrisk/styled-system'
import { Property } from 'csstype'

export interface WordBreakProps {
  wordBreak?: ResponsiveValue<Property.WordBreak>
}

export const wordBreak = system({
  wordBreak: true,
})
