import { isValidPhoneNumber } from '@wrisk/ui-components'

import { required } from '../helpers'
import { ValidationFunctions } from '../types'

export const phoneNumber: ValidationFunctions = {
  _self: {
    required,
    format: (phoneNumberFormat: { prefix: string; regex: string }) => (value: string) =>
      isValidPhoneNumber(
        phoneNumberFormat.prefix,
        new RegExp(phoneNumberFormat.regex),
      )(value),
  },
}
