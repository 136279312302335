import {
  border,
  color,
  ColorProps,
  compose,
  css,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  ResponsiveValue,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { transition, TransitionProps } from '../../transition'
import { mdBumps } from '../layout'
import { TypoSizeProps, typoSizes } from '../typography'
import { Input, InputProps } from './Input'

export type TextInputProperty = 'default' | 'ghost' | 'licencePlate'

export type TextInputProps = InputProps &
  TransitionProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  ColorProps &
  TypoSizeProps &
  FlexboxProps & {
    variant?: ResponsiveValue<TextInputProperty>
  }

const defaultProps = withDefaultProps<TextInputProps>({
  variant: 'default',
  type: 'text',
  typoSize: 'base',
})

export const TextInput = styled(Input).attrs<TextInputProps>(defaultProps)(
  {
    '-webkit-appearance': 'none',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },
  css({
    borderRadius: 2,
    fontFamily: 'input',
    border: 0,
    zIndex: 100,
  }),
  variant({
    scale: 'TextInput',
    variants: {
      default: {
        boxShadow: 'default',
        p: mdBumps,
        bg: 'chalk',
        '&:focus': {
          boxShadow: 'input',
        },
      },
      ghost: {
        boxShadow: 'none',
        py: mdBumps,
        px: 0,
      },
      licencePlate: {
        fontFamily: 'licencePlate',
        boxShadow: 'none',
        py: mdBumps,
        px: 0,
      },
    },
  }),
  typoSizes,
  compose(transition, border, space, layout, typography, color, flexbox),
)
