import {
  compose,
  css,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { displayOn, DisplayOnProps } from '../atoms'
import { withDefaultProps } from '../defaultProps'

export type TabBarProperty = 'default'

export interface TabBarProps extends SpaceProps, LayoutProps, DisplayOnProps {
  variant?: TabBarProperty
}

const defaultProps = withDefaultProps<TabBarProps>({
  variant: 'default',
})

export const TabBar = styled.div.attrs<TabBarProps>(defaultProps)(
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: 'divider',
    borderStyle: 'solid',
    borderWidth: 0,
  }),
  variant({
    scale: 'TabBar',
    variants: {
      default: {
        borderBottomWidth: 1,
      },
    },
  }),
  displayOn,
  compose(space, layout),
)
