'use client'

import {
  BackgroundColorProps,
  border,
  BorderProps,
  boxShadow,
  BoxShadowProps,
  color,
  compose,
  css,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from '@wrisk/styled-system'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { gap, GapProps } from '../containers/gap'
import { containerStyle, ContainerStyleProps } from './containerStyle'
import { displayOn, DisplayOnProps } from './displayOn'

export type FlexProps = BackgroundColorProps &
  BorderProps &
  BoxShadowProps &
  ContainerStyleProps &
  DisplayOnProps &
  FlexboxProps &
  GapProps &
  LayoutProps &
  PositionProps &
  PropsWithChildren &
  SpaceProps

const defaultProps = withDefaultProps<FlexProps>({
  variant: 'default',
  displayOn: 'default',
})

export const Flex = styled.div.attrs<FlexProps>(defaultProps)(
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: 'divider',
    borderStyle: 'solid',
    borderWidth: 0,
  }),
  containerStyle,
  displayOn,
  compose(flexbox, layout, space, border, color, position, gap, boxShadow),
)
