import { ResponsiveValue, system } from '@wrisk/styled-system'
import { Property } from 'csstype'

export interface WhiteSpaceProps {
  whiteSpace?: ResponsiveValue<Property.WhiteSpace>
}

export const whiteSpace = system({
  whiteSpace: true,
})
