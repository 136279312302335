import {
  Box,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  mdBumps,
  ModalHeader,
  PopupMenu,
  PrimaryButton,
  TertiaryButton,
  Typo,
  useModal,
  xsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { MenuAnchor } from '../../../BasePage/UserNav/MenuAnchor'
import { isPolicy } from '../../../domain'
import { useErrorHandlingAsyncCallback } from '../../../hooks/errors'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { usePrincipal } from '../../authentication'
import { useProposal } from '../../product/proposal/proposalContext'
import { useRefresh } from '../../product/RefreshProvider'

const tKey = TKey('components.manage-quote-menu')

interface ConfirmDeleteModalContentProps {
  proposalCode: string
  onDeleted?: () => void
}

function ConfirmDeleteModalContent({
  proposalCode,
  onDeleted,
}: ConfirmDeleteModalContentProps) {
  const { apiClient } = usePrincipal()

  const { t } = useWriskTranslation()
  const { hide } = useModal()

  const { execute, loading } = useErrorHandlingAsyncCallback(async () => {
    await apiClient.deleteProposal(proposalCode)
    onDeleted?.()
    hide()
  })

  return (
    <Box>
      <ModalHeader
        className='modal-header'
        header={t(tKey('header'))}
        subheader={t(tKey('subheader'))}
      />
      <Flex width={1}>
        <PrimaryButton
          loading={loading}
          onClick={execute}
          variant='critical'
          width={1}
          mr={2}
        >
          {t(tKey('actions.delete'))}
        </PrimaryButton>
        <TertiaryButton width={1} onClick={hide}>
          {t(tKey('actions.cancel'))}
        </TertiaryButton>
      </Flex>
    </Box>
  )
}

export const ManageProposalMenu: FunctionComponent<FlexProps> = () => {
  const { t } = useWriskTranslation()
  const modal = useModal()
  const { refresh } = useRefresh()
  const { proposal } = useProposal()

  const [menuVisible, setMenuVisibility] = useState(false)

  const toggleMenu = useCallback(() => setMenuVisibility((previous) => !previous), [])

  const showConfirmDeleteModal = useCallback(() => {
    setMenuVisibility(false)
    modal.show({
      content: (
        <ConfirmDeleteModalContent
          proposalCode={proposal.proposalCode}
          onDeleted={refresh}
        />
      ),
    })
  }, [modal, proposal, refresh])

  return (
    <Flex
      p={xsBumps}
      pt={mdBumps}
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='flex-end'
    >
      {!isPolicy(proposal) && (
        <IconButton mt={-3} onClick={toggleMenu}>
          <Icon icon='more' size='small' />
        </IconButton>
      )}
      <PopupMenu variant='manageQuote' visible={menuVisible} onDismiss={toggleMenu}>
        <Box width={[1, 1]} py={2}>
          <MenuAnchor py={2} onClick={showConfirmDeleteModal}>
            <Typo variant='buttons' typoSize='sm' color='textCritical'>
              {t('components.manage-proposal-menu.actions.delete')}
            </Typo>
          </MenuAnchor>
        </Box>
      </PopupMenu>
    </Flex>
  )
}
