import { isNil, isObject, negate } from 'lodash'

export const isNotNil = <T>(value: T | null | undefined): value is T =>
  negate(isNil)(value)

export const required = (option: boolean) => (value: unknown) =>
  !option
    ? true
    : isObject(value)
    ? Object.values(value).every(isNotNil)
    : isNotNil(value)

export const onlyAccept = (accept: boolean) => (value: boolean | undefined) =>
  value === accept

export const raiseError = (message: string) => {
  throw new Error(message)
}
