import { compose, css, layout, LayoutProps, variant } from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../defaultProps'

export type CollapsibleSectionContentProperty = 'default'

export interface CollapsibleSectionContentProps extends LayoutProps {
  variant?: CollapsibleSectionContentProperty
}

const defaultProps = withDefaultProps<CollapsibleSectionContentProps>({
  variant: 'default',
})

export const CollapsibleSectionContent = styled.div.attrs<CollapsibleSectionContentProps>(
  defaultProps,
)(
  css({
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    width: '100%',
  }),
  variant({
    scale: 'CollapsibleSectionContent',
    variants: {
      default: {},
    },
  }),
  compose(layout),
)
