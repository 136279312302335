import airbrake from './airbrake'

/**
 * Types for Airbrake
 */
export enum AirbrakeSeverity {
  debug = 'debug',
  info = 'info',
  warning = 'warning',
  error = 'error',
  critical = 'critical',
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
type AirbrakeError = string | any
type AirbrakeData = any

/* eslint-enable  @typescript-eslint/no-explicit-any */

/**
 * Fires Airbrake notification if the instance is configured properly
 * @param {string} severity - severity of notification: warning, error, and so on
 * @param {string | any} error - text or object with error data, data from catch(error) is fully supported
 * @param {any} [data] - optional additional data, passed to airbrake.notify() as spread
 */
export function airbrakeNotify(
  severity: AirbrakeSeverity,
  error: AirbrakeError,
  data?: AirbrakeData,
) {
  if (!airbrake) {
    console.warn(
      `Airbrake is not configured! The "${severity}" notification for "${
        error?.toString() || JSON.stringify(error)
      }" does nothing...`,
    )
  }
  const more = data && typeof data === 'object' ? data : {}
  const payload = {
    context: { severity: severity },
    error: error,
    ...more,
  }
  airbrake?.notify(payload) // This method is async, returns Promise<INotice>
}

/**
 * Shortcuts for Airbrake notifications
 */
export function airbrakeCritical(error: AirbrakeError, data?: AirbrakeData) {
  airbrakeNotify(AirbrakeSeverity.critical, error, data)
}

export function airbrakeDebug(error: AirbrakeError, data?: AirbrakeData) {
  airbrakeNotify(AirbrakeSeverity.debug, error, data)
}

export function airbrakeError(error: AirbrakeError, data?: AirbrakeData) {
  airbrakeNotify(AirbrakeSeverity.error, error, data)
}

export function airbrakeWarning(error: AirbrakeError, data?: AirbrakeData) {
  airbrakeNotify(AirbrakeSeverity.warning, error, data)
}
