import { mdBumps, TabItem, TabView } from '@wrisk/ui-components'
import { inRange } from 'lodash'
import React, { FunctionComponent, useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { getUsablePaymentMethod, PaymentMethodType } from '../../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { FullPage } from '../../../../templates'
import { useCustomerPayment } from '../../../CustomerPaymentProvider'
import { usePaymentMethods } from '../../../policy/billing'
import { CheckoutDirectDebitOnlyTab } from './CheckoutDirectDebitOnlyTab'
import { CheckoutDirectDebitWithCardTab } from './CheckoutDirectDebitWithCardTab'

const tKey = TKey('pages.admin-checkout-tabs')

export const ACTIVE_TAB_SEARCH_PARAM = 'tab'

export const CheckoutDirectDebitTabs: FunctionComponent = () => {
  const { t } = useWriskTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const { paymentMethods } = usePaymentMethods()
  const { customerPayment } = useCustomerPayment()

  const paymentMethod = getUsablePaymentMethod(
    paymentMethods,
    PaymentMethodType.BACS_DEBIT,
  )

  const items: TabItem[] = [
    customerPayment && {
      component: () => (
        <CheckoutDirectDebitWithCardTab
          paymentMethod={paymentMethod}
          customerPayment={customerPayment}
        />
      ),
      header: t(tKey('tabs', 'dd-card')),
    },
    {
      component: () => <CheckoutDirectDebitOnlyTab paymentMethod={paymentMethod} />,
      header: t(tKey('tabs', 'dd-only')),
    },
  ].filter(Boolean) as TabItem[]

  const tabParam = Number(searchParams.get(ACTIVE_TAB_SEARCH_PARAM))
  const [tab, setTab] = useState(inRange(tabParam, items.length) ? tabParam : 0)

  const handleTabChange = useCallback(
    (newTab: number) => {
      setTab(newTab)
      setSearchParams(
        (prev) =>
          new URLSearchParams({
            ...Object.fromEntries(prev),
            [ACTIVE_TAB_SEARCH_PARAM]: String(newTab),
          }),
        { replace: true },
      )
    },
    [setSearchParams],
  )

  return (
    <FullPage pageId='checkout-page' header={t(tKey('header'))}>
      <TabView
        width={1}
        mb={mdBumps}
        items={items}
        tabIndex={tab}
        onTabChange={handleTabChange}
      />
    </FullPage>
  )
}
