import {
  compose,
  css,
  DisplayProps,
  flexbox,
  FlexboxProps,
  layout,
  variant,
} from '@wrisk/styled-system'
import { default as React, FunctionComponent } from 'react'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { Box, BoxProps, mdBumps, xsBumps } from '../layout'
import { Heading, Typo } from '../typography'

export type SectionProperty = 'default'

export interface SectionProps extends FlexboxProps, BoxProps, DisplayProps {
  variant?: SectionProperty
}

const defaultProps = withDefaultProps<SectionProps>({
  variant: 'default',
})

export const Section = styled(Box).attrs<SectionProps>(defaultProps)(
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  variant({
    scale: 'Section',
    variants: {
      default: {},
    },
  }),
  compose(flexbox, layout),
)

export const SectionTitle: FunctionComponent<BoxProps> = ({ children, ...props }) => (
  <Box {...props}>
    <Heading variant='h3' as='h3' typoSize='lg'>
      {children}
    </Heading>
  </Box>
)

export const SectionDescription: FunctionComponent<BoxProps> = ({
  children,
  ...props
}) => (
  <Box width={1} pt={xsBumps} {...props}>
    <Typo color='bodySecondary'>{children}</Typo>
  </Box>
)

export const SectionContent: FunctionComponent<BoxProps> = ({ children, ...props }) => (
  <Box width={1} pt={mdBumps} {...props}>
    <Typo>{children}</Typo>
  </Box>
)
