import { Box, BoxProps, smBumps } from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { TKeyBuilder } from '../../../infrastructure/internationalisation'
import { InputConfig } from '../../../state/configuration'
import { getInput } from '../maps'
import { getValidationRules } from '../validation'
import { ErrorMessage } from './ErrorMessage'
import { QuestionLoop } from './QuestionLoop'

interface FormElementProps extends BoxProps {
  input: InputConfig
  inputs: InputConfig[]
  errorClassName?: string

  tKey: TKeyBuilder
  t: TFunction
}

export const FormElement: FunctionComponent<FormElementProps> = ({
  input: { type, meta, name, validation },
  inputs,
  errorClassName,
  tKey,
  t,
  ...props
}) => {
  const { control } = useFormContext()
  const Input = getInput(type)

  return (
    <Box width={1} {...props}>
      {type === 'questionLoop' ? (
        <QuestionLoop
          name={name}
          type={type}
          validation={validation}
          meta={meta ?? {}}
          errorMessageClass={errorClassName}
          tKey={tKey}
          t={t}
        />
      ) : (
        <Controller
          name={name}
          control={control}
          rules={{
            validate: getValidationRules(type, validation, inputs),
          }}
          render={({ field: { name: controllerName, value, onChange, onBlur } }) => (
            <Input
              name={controllerName}
              onChange={onChange}
              value={value}
              onBlur={onBlur}
              meta={meta}
              validation={validation}
              errorMessageClass={errorClassName}
              tKey={tKey}
              tName={name}
              t={t}
            />
          )}
        />
      )}
      {Boolean(errorClassName) && (
        <ErrorMessage
          name={name}
          t={t}
          tKey={tKey}
          tName={name}
          className={errorClassName}
          pt={smBumps}
        />
      )}
    </Box>
  )
}
