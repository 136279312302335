import {
  compose,
  css,
  ResponsiveStyleValue,
  typography,
  TypographyProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { TypoSizeProps, typoSizes } from './typoSizes'

export type StyledPremiumProperty = 'default' | 'inverted'

export interface StyledPremiumProps extends TypoSizeProps, TypographyProps {
  variant?: ResponsiveStyleValue<StyledPremiumProperty>
}

const defaultProps = withDefaultProps<StyledPremiumProps>({
  variant: 'default',
  typoSize: 'base',
})

export const StyledPremium = styled.div.attrs<StyledPremiumProps>(defaultProps)(
  css({
    display: 'inline-block',
    whiteSpace: 'nowrap',
    my: -1,
    py: 1,
  }),
  variant({
    scale: 'StyledPremium',
    variants: {
      default: {},
      inverted: {
        backgroundColor: 'body',
        color: 'chalk',
        px: 2,
        borderRadius: 2,
      },
    },
  }),
  compose(typoSizes, typography),
)
