import { DateTime } from 'luxon'

import { required } from '../helpers'
import { ValidationFunctions } from '../types'

export const yearMonth: ValidationFunctions = {
  _self: {
    required,
    minYear: (minYear: number) => (value: string) =>
      DateTime.fromISO(value).year >= minYear,
  },
}
