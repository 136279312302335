import { Box, ModalContextObj, ModalHeader, Typo, useModal } from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { useEffect } from 'react'
import {
  useAsync,
  useAsyncCallback,
  UseAsyncCallbackOptions,
  UseAsyncOptions,
} from 'react-async-hook'

import { ApiError, isApiException, isRequestAbortedException } from '../clients/api'
import {
  isAuthenticationError,
  isAuthenticationLoginRequiredError,
} from '../clients/auth'
import { TKey, useWriskTranslation } from '../infrastructure/internationalisation'
import { logger } from '../infrastructure/logging'

export function useErrorHandlingAsync<R = unknown, Args extends unknown[] = unknown[]>(
  asyncFunction: (...args: Args) => Promise<R>,
  params: Args,
  onAcknowledge?: (error: Error) => void,
  options?: UseAsyncOptions<R>,
) {
  const asyncReturn = useAsync(asyncFunction, params, options)

  useHandlingErrorWithNotification(asyncReturn.error, onAcknowledge)
  return asyncReturn
}

export function useErrorHandlingAsyncCallback<
  R = unknown,
  Args extends unknown[] = unknown[],
>(
  asyncFunction: (...args: Args) => Promise<R> | R,
  onAcknowledge?: (error: Error) => void,
  options?: UseAsyncCallbackOptions<R>,
) {
  const asyncReturn = useAsyncCallback(asyncFunction, options)
  useHandlingErrorWithNotification(asyncReturn.error, onAcknowledge)
  return asyncReturn
}

export const maybeShowErrorModal =
  (modal: ModalContextObj, t: TFunction<string, undefined, string>) =>
  (error: Error | undefined, onAcknowledge?: (error: Error) => void) => {
    if (error && !isRequestAbortedException(error)) {
      const errorContent = toErrorContent(error, t)

      logger.error(error.message)

      modal.show({
        content: (
          <Box>
            <ModalHeader header={errorContent.header} />
            <Typo>{errorContent.content}</Typo>
          </Box>
        ),
        onClose: () => onAcknowledge?.(error),
      })
    }
  }

const useHandlingErrorWithNotification = (
  error: Error | undefined,
  onAcknowledge?: (error: Error) => void,
) => {
  const modal = useModal()
  const { t } = useWriskTranslation()

  useEffect(() => {
    maybeShowErrorModal(modal, t)(error, onAcknowledge)
  }, [error])
}

const tKey = TKey('components.error-modal')

const toErrorContent = (error: Error, t: TFunction) => {
  if (isApiException(error)) {
    return {
      header: t(tKey(error.type, 'header')),
      content: t(tKey(error.type, 'content')),
    }
  } else if (isAuthenticationLoginRequiredError(error)) {
    return {
      header: t(tKey(ApiError.UNAUTHORIZED, 'header')),
      content: t(tKey(ApiError.UNAUTHORIZED, 'content')),
    }
  } else if (isAuthenticationError(error)) {
    return {
      header: t(tKey('UNEXPECTED', 'header')),
      content: error.error_description,
    }
  } else {
    return {
      header: t(tKey('UNEXPECTED', 'header')),
      content: `${error.name}: ${error.message}`,
    }
  }
}
