import { isValidEmail } from '@wrisk/ui-components'

import { required } from '../helpers'
import { ValidationFunctions } from '../types'

export const email: ValidationFunctions = {
  _self: {
    required,
    isValidEmail: () => (value: string) => isValidEmail(value),
  },
}
