import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

import { createRepository } from '../../../infrastructure/storage'
import { AppPath } from '../../AppPath'

interface ImpersonateContextObj {
  impersonate: string | null
  setImpersonate: (impersonate: string) => void
}

const ImpersonateContext = createContext<ImpersonateContextObj | undefined>(undefined)

export interface ImpersonateProviderProps extends PropsWithChildren {
  impersonate: string | null
}

const repository = createRepository<string>('impersonate', 'session')

export const ImpersonateProvider: FunctionComponent<ImpersonateProviderProps> = (
  props,
) => {
  const navigate = useNavigate()

  const [impersonate, setImpersonate] = useState<string | null>(() => {
    if (props.impersonate) {
      repository.save(props.impersonate)
    }

    return repository.get()
  })

  const value = useMemo(
    () => ({
      impersonate,
      setImpersonate: (value) => {
        repository.save(value)
        setImpersonate(value)
        navigate(AppPath.HOME, { replace: true })
      },
    }),
    [impersonate, navigate],
  )

  return (
    <ImpersonateContext.Provider value={value}>
      {props.children}
    </ImpersonateContext.Provider>
  )
}

export const useImpersonate = (): ImpersonateContextObj => {
  const context = useContext(ImpersonateContext)
  if (!context) {
    throw new Error('Cannot use ImpersonateContext when undefined.')
  }
  return context
}
