import {
  Box,
  Flex,
  Form,
  ModalHeader,
  PrimaryButton,
  smBumps,
  TextInput,
  useModal,
} from '@wrisk/ui-components'
import React, { useCallback, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { ApiException, withApiErrorHandling } from '../../../clients/api'
import { StripeMotoSettleError } from '../../../clients/api/clients/paymentCard'
import { useApiErrorHandlingAsyncCallback } from '../../../hooks/auth'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { getFormats, useConfig } from '../../../state/configuration'
import { ErrorMessage } from '../../formBuilder'
import { DateFormInput } from '../../formBuilder/inputs'
import { FormSubHeader } from '../form'
import { AdminSettleErrorNotification } from './AdminSettleErrorNotification'

interface AdminStripeModalFormData {
  dateOfBirth: string
  postalCode: string
  token: string
}

interface AdminSettleModalContentProps {
  invokeApi: (props: AdminSettleModalFormProps) => Promise<void>
  dateOfBirth?: string
  onSuccess?: () => void
}

export interface AdminSettleModalFormProps {
  postalCode: string
  token: string
}

const tKey = TKey('components.admin-settle-modal')

export const AdminSettleModalContent = ({
  invokeApi,
  dateOfBirth,
  onSuccess,
}: AdminSettleModalContentProps) => {
  const { t } = useWriskTranslation()
  const { postalCode, stripeToken } = useConfig(getFormats)

  const { hide } = useModal()

  const [apiError, setApiError] = useState<ApiException<StripeMotoSettleError> | null>(
    null,
  )

  const formContextValue = useForm<AdminStripeModalFormData>({ mode: 'onTouched' })

  const {
    control,
    formState: { isValidating, isValid, isSubmitting },
    handleSubmit,
    setValue,
  } = formContextValue

  const { execute } = useApiErrorHandlingAsyncCallback(
    async (props: AdminSettleModalFormProps) => {
      await withApiErrorHandling(
        async () => {
          await invokeApi(props)
          onSuccess?.()
          hide()
        },
        setApiError,
        [400, 402],
      )
    },
  )

  const handlePasteToken = useCallback(
    (clipboardToken: string) =>
      setValue('token', clipboardToken.trim(), { shouldValidate: true }),
    [setValue],
  )

  return (
    <FormProvider {...formContextValue}>
      <Form data-testid='form-settle' onSubmit={handleSubmit(execute)}>
        <Flex
          flexDirection='column'
          alignItems='stretch'
          justifyContent='stretch'
          gap={8}
        >
          <ModalHeader header={t(tKey('header'))} subheader={t(tKey('subheader'))} />

          <Box>
            <FormSubHeader
              header={t(tKey('form.stripe-token.header'))}
              mb={smBumps}
              pasteButtonLabel={t(tKey('form.stripe-token.paste-button'))}
              onPaste={handlePasteToken}
            />
            <Controller
              name='token'
              control={control}
              rules={{ required: true, pattern: new RegExp(stripeToken.regex) }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  placeholder={t(tKey('form.stripe-token.placeholder'))}
                  width={1}
                />
              )}
            />
            <ErrorMessage
              name='token'
              t={t}
              tKey={tKey}
              tName='form.stripe-token'
              mt={2}
            />
          </Box>

          <Box>
            <FormSubHeader
              header={t(tKey('form.postal-code.header'))}
              subheader={t(tKey('form.postal-code.subheader'))}
              mb={smBumps}
            />
            <Controller
              name='postalCode'
              control={control}
              rules={{ required: true, pattern: new RegExp(postalCode.regex) }}
              render={({ field: { onChange, ...field } }) => (
                <TextInput
                  {...field}
                  onChange={({ target: { value } }) => onChange(value.toUpperCase())}
                  placeholder={t(tKey('form.postal-code.placeholder'))}
                  width={1}
                />
              )}
            />
            <ErrorMessage
              name='postalCode'
              t={t}
              tKey={tKey}
              tName='form.postal-code'
              mt={2}
            />
          </Box>

          <Box>
            <FormSubHeader
              header={t(tKey('form.date-of-birth.header'))}
              subheader={t(tKey('form.date-of-birth.subheader'))}
              mb={smBumps}
            />
            <Controller
              name='dateOfBirth'
              control={control}
              rules={{
                required: true,
                validate: (value) => value === dateOfBirth,
              }}
              render={({
                field: {
                  // eslint-disable-next-line unused-imports/no-unused-vars
                  ref,
                  ...field
                },
              }) => (
                <DateFormInput
                  meta={undefined}
                  t={t}
                  tKey={tKey}
                  tName={''}
                  validation={undefined}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name='dateOfBirth'
              t={t}
              tKey={tKey}
              tName='form.date-of-birth'
              mt={2}
            />
          </Box>

          {apiError && <AdminSettleErrorNotification tKey={tKey} data={apiError.data} />}

          <PrimaryButton
            disabled={!isValid}
            layout='fixed'
            loading={isSubmitting || isValidating}
            type='submit'
          >
            {t(tKey('form.submit'))}
          </PrimaryButton>
        </Flex>
      </Form>
    </FormProvider>
  )
}
