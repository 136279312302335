import { css } from '@wrisk/styled-system'
import styled from 'styled-components'

import { mdBumps } from '../layout'

export const GhostSelect = styled.select(
  css({
    py: mdBumps,
  }),
  (props) => ({
    border: 'none',
    appearance: 'none',
    '-webkit-appearance': 'none',
    cursor: 'pointer',
    width: '100%',

    background: `url(${props.theme.icons.chevronDown.url}) no-repeat right center`,
    backgroundSize: '16px',

    '&:focus': {
      outline: '0',
    },
    '&[disabled] &:disabled': {
      opacity: '0.4',
      cursor: 'not-allowed',
    },
    '&:not(:focus):invalid': {
      color: '${(props) => props.theme.colors.placeholder}',
    },
  }),
)
