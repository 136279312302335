import { NotificationProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { isRenewalPolicy, ProposalStatus } from '../../../../domain'
import { usePolicy } from '../../../product/policy/policyContext'
import { RenewalQuoteBanner } from './RenewalQuoteBanner'
import { RenewalReferralBanner } from './RenewalReferralBanner'

export const RenewalBanner: FunctionComponent<NotificationProps> = (props) => {
  const { policy, setPolicy } = usePolicy()

  return isRenewalPolicy(policy) && !policy.policyDetail.renewalStopped
    ? (() => {
        switch (policy.renewalProposal.status) {
          case ProposalStatus.QUOTE:
            return <RenewalQuoteBanner policy={policy} onChange={setPolicy} {...props} />
          case ProposalStatus.REFERRAL:
            return <RenewalReferralBanner policy={policy} {...props} />
          case ProposalStatus.POLICY:
          case ProposalStatus.INSUFFICIENT_ACCURACY:
          case ProposalStatus.QUOTE_EXPIRED:
            return null
        }
      })()
    : null
}
