import {
  Box,
  Flex,
  Grid,
  Heading,
  HelpFlexAnchor,
  Icon,
  lgBumps,
  mdBumps,
  PrimaryButton,
  SecondaryButton,
  smBumps,
  Status,
  Typo,
  xlBumps,
  xsBumps,
  xxsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Navigate } from 'react-router-dom'

import { getCurrentQuote, getUpsellProposal, Proposal } from '../../../../domain'
import { useNavigateCallback } from '../../../../hooks/navigation'
import { useQuotePremiums } from '../../../../hooks/premiums'
import { useContinueProposalCallback } from '../../../../hooks/proposal'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { Page } from '../../../../infrastructure/routing'
import { FullPage } from '../../../templates'
import { useProduct } from '../../productContext'
import { useProposal } from '../proposalContext'

const tKey = TKey('pages.proposal-upgrade')

interface ContentsProps extends ProposalUpgradePageProps {
  upsellProposal: Proposal
}

const Content: FunctionComponent<ContentsProps> = ({ parent, upsellProposal }) => {
  const { t } = useWriskTranslation()

  const onContinueProposalCallback = useContinueProposalCallback(upsellProposal)
  const onContinue = useNavigateCallback(parent.url)

  const { product } = useProduct()

  const quote = getCurrentQuote(upsellProposal)

  const { totals } = useQuotePremiums(quote, product)

  const upsell = t(tKey(upsellProposal.schemeCode, 'upsell'), {
    returnObjects: true,
    defaultValue: [],
  }) as Array<{
    header: string
    content: string
  }>

  return (
    <FullPage
      pageId='proposal-upgrade-page'
      upperHeader={
        <Status variant='info' typoSize='sm'>
          {t(tKey(upsellProposal.schemeCode, 'tagline'))}
        </Status>
      }
      header={t(tKey(upsellProposal.schemeCode, 'header'))}
      subheader={t(tKey(upsellProposal.schemeCode, 'subheader'))}
    >
      {Boolean(upsell.length) && (
        <Grid
          columnGap={mdBumps}
          rowGap={2}
          width={1}
          gridTemplateColumns={['1fr', '1fr 1fr']}
          gridAutoRows='1fr'
          mb={xlBumps}
        >
          {upsell.map(({ content, header }) => (
            <Box key={header} pt={smBumps} pb={lgBumps} borderTopWidth={1}>
              <Heading variant='h3' mb={xsBumps}>
                {header}
              </Heading>
              <Typo typoSize='sm'>{content}</Typo>
            </Box>
          ))}
        </Grid>
      )}

      <Flex width={1} justifyContent='center' mb={xlBumps}>
        <HelpFlexAnchor
          fontFamily='buttons'
          fontWeight='bold'
          typoSize='sm'
          href={t<string>(tKey(upsellProposal.schemeCode, 'comparison', 'link'))}
        >
          {t(tKey(upsellProposal.schemeCode, 'comparison', 'text'))}
          <Icon icon='info' size='tiny' ml={1} />
        </HelpFlexAnchor>
      </Flex>

      <Flex flexDirection='column' justifyContent='center' width={1}>
        <PrimaryButton
          data-testid='upgrade-proposal'
          mb={xxsBumps}
          minWidth={['100%', '386px']}
          onClick={onContinueProposalCallback}
        >
          <Typo>{t(tKey(upsellProposal.schemeCode, 'actions.yes'))}</Typo>
          <Typo typoSize='sm' fontWeight='normal' textTransform='lowercase'>
            {t(tKey(upsellProposal.schemeCode, 'actions.priced'), {
              premium: t(tFormats('currency.long'), {
                amount: totals.discounted,
              }),
            })}
          </Typo>
        </PrimaryButton>
        <SecondaryButton
          data-testid='continue-proposal'
          minWidth={['100%', '386px']}
          onClick={onContinue}
        >
          {t(tKey(upsellProposal.schemeCode, 'actions.no'))}
        </SecondaryButton>
      </Flex>
    </FullPage>
  )
}

export interface ProposalUpgradePageProps {
  parent: Page
}

export const ProposalUpgradePage: FunctionComponent<ProposalUpgradePageProps> = ({
  parent,
}) => {
  const { proposal } = useProposal()

  const upsellProposal = getUpsellProposal(proposal)

  return upsellProposal ? (
    <Content parent={parent} upsellProposal={upsellProposal} />
  ) : (
    <Navigate to={parent.url} />
  )
}
