import { PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { generatePath, Navigate, useNavigate, useSearchParams } from 'react-router-dom'

import { useApiErrorHandlingAsync } from '../../../../hooks/auth'
import { AppPath } from '../../../AppPath'
import { usePrincipal } from '../../../authentication'
import { useProduct } from '../../productContext'
import { ProposalPath } from '../ProposalPath'
import { useCreateProposalDataReducers } from './hooks'

const getExternalReference = (params: URLSearchParams) => params.get('ref') ?? undefined
const getSource = (params: URLSearchParams) => params.get('source') ?? undefined
const getPromoCode = (params: URLSearchParams) => params.get('promoCode') ?? undefined

export const CreateProposal: FunctionComponent = () => {
  const navigate = useNavigate()

  const createProposalData = useCreateProposalDataReducers()
  const { apiClient } = usePrincipal()
  const [params] = useSearchParams()

  const {
    scheme: { schemeCode, disabled },
  } = useProduct()

  const createProposalAsync = useApiErrorHandlingAsync(async () => {
    const proposals = await apiClient.getProposals({ schemeCode })
    const policies = await apiClient.getPolicies({ schemeCode })

    if (disabled || proposals.length + policies.length) {
      return undefined
    } else {
      const data = await createProposalData()
      const externalReference = getExternalReference(params)
      const source = getSource(params)
      const promoCode = getPromoCode(params)

      const proposal = await apiClient.createProposal({
        schemeCode,
        externalReference,
        data,
        source,
        promoCode,
      })
      navigate(
        generatePath(ProposalPath.PROPOSAL, { proposalCode: proposal.proposalCode }),
        { replace: true },
      )
    }
  }, [])

  return createProposalAsync.loading ? (
    <PageLoadingIndicator />
  ) : createProposalAsync.result ? null : (
    <Navigate to={AppPath.HOME} />
  )
}
