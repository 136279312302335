import {
  color,
  ColorProps,
  compose,
  css,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { TypoSizeProps, typoSizes } from './typoSizes'
import { whiteSpace, WhiteSpaceProps } from './whiteSpace'

export type StatusProperty = 'default' | 'success' | 'warning' | 'info' | 'critical'

export interface StatusProps
  extends TypoSizeProps,
    SpaceProps,
    WhiteSpaceProps,
    TypographyProps,
    ColorProps {
  variant?: StatusProperty
}

const defaultProps = withDefaultProps<StatusProps>({
  variant: 'default',
  typoSize: 'base',
})

export const Status = styled.div.attrs<StatusProps>(defaultProps)(
  css({
    display: 'inline-block',
    fontFamily: 'buttons',
    borderRadius: '1',
    fontWeight: 'bold',
    py: 1,
    px: 3,
  }),
  variant({
    scale: 'Status',
    variants: {
      default: {
        bg: 'surfaceHighlightSubdued',
        color: 'textHighlight',
      },
      info: {
        bg: 'surfaceInfoSubdued',
        color: 'textInfo',
      },
      success: {
        bg: 'surfaceSuccessSubdued',
        color: 'textSuccess',
      },
      warning: {
        bg: 'surfaceWarningSubdued',
        color: 'textWarning',
      },
      critical: {
        bg: 'surfaceCriticalSubdued',
        color: 'textCritical',
      },
    },
  }),
  typoSizes,
  compose(whiteSpace, typography, color, space),
)
