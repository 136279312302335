import React, { FunctionComponent } from 'react'

import { PaymentMethodType } from '../../../../../domain'
import { useCustomerAccount } from '../../../policy/billing'
import { CheckoutDisabled } from '../CheckoutDisabled'
import { CheckoutDirectDebitTabs } from './CheckoutDirectDebitTabs'

export const AdminCheckout: FunctionComponent = () => {
  const { customerAccount } = useCustomerAccount()

  switch (customerAccount?.paymentMethodType) {
    case PaymentMethodType.BACS_DEBIT:
      return <CheckoutDirectDebitTabs />
    default:
      return <CheckoutDisabled />
  }
}
