import React, { FunctionComponent } from 'react'

import { usePrincipal } from '../../../authentication'
import { CheckoutManager } from '../../CheckoutManager'
import { AdminCheckout } from './AdminCheckout'
import { UserCheckout } from './UserCheckout'

export const CheckoutPage: FunctionComponent = () => {
  const { isAdmin } = usePrincipal()

  const checkoutPage = isAdmin ? <AdminCheckout /> : <UserCheckout />
  return <CheckoutManager>{checkoutPage}</CheckoutManager>
}
