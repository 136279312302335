import { PrimaryButton } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { PaymentMethod } from '../../../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../../../../hooks/auth'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { usePrincipal } from '../../../../authentication'
import { useProposal } from '../../proposalContext'
import { CheckoutDirectDebitContent } from './CheckoutDirectDebitContent'

interface CheckoutDirectDebitOnlyTabProps {
  paymentMethod: PaymentMethod | undefined
}

const tKey = TKey('components.admin-checkout-direct-debit-only')

export const CheckoutDirectDebitOnlyTab: FunctionComponent<
  CheckoutDirectDebitOnlyTabProps
> = ({ paymentMethod }) => {
  const { t } = useWriskTranslation()

  const { apiClient } = usePrincipal()

  const { proposal, setProposal } = useProposal()

  const onCheckout = useApiErrorHandlingAsyncCallback(async () =>
    setProposal(await apiClient.bindProposal(proposal.proposalCode)),
  )

  const buttonDescription = t(tKey('actions', 'bind'))

  return (
    <CheckoutDirectDebitContent tKey={tKey}>
      <PrimaryButton
        data-testid='bind-proposal'
        layout='fixed'
        onClick={onCheckout.execute}
        loading={onCheckout.loading}
        disabled={!paymentMethod}
      >
        {buttonDescription}
      </PrimaryButton>
    </CheckoutDirectDebitContent>
  )
}
