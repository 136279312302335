import {
  Section,
  SectionContent,
  SectionDescription,
  SectionProps,
  SectionTitle,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  getCurrentQuote,
  getUsablePaymentMethod,
  PaymentMethodType,
} from '../../../../../domain'
import { useQuotePremiums } from '../../../../../hooks/premiums'
import {
  TKey,
  tPaymentMethods,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { usePaymentMethods } from '../../../policy/billing'
import { useProduct } from '../../../productContext'
import { useProposal } from '../../proposalContext'
import { DirectDebitSetup } from '../DirectDebitSetup'

const tKey = TKey('components.mandate-section')

export const MandateSection: FunctionComponent<SectionProps> = (props) => {
  const { t } = useWriskTranslation()

  const { paymentMethods } = usePaymentMethods()
  const { proposal } = useProposal()
  const { product } = useProduct()
  const quote = getCurrentQuote(proposal)

  const { totals } = useQuotePremiums(quote, product)

  const paymentMethod = getUsablePaymentMethod(
    paymentMethods,
    PaymentMethodType.BACS_DEBIT,
  )

  const mandateDescription = totals.discounted
    ? t(tKey('subheader', 'pay'), {
        statement: t(tPaymentMethods('statement')),
        defaultValue: null,
      })
    : t(tKey('subheader', 'bind'), { defaultValue: null })

  return (
    <Section width={1} {...props}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>
      {mandateDescription && (
        <SectionDescription>{mandateDescription}</SectionDescription>
      )}
      <SectionContent>
        <DirectDebitSetup mandate={paymentMethod} />
      </SectionContent>
    </Section>
  )
}
