import {
  boxShadow,
  BoxShadowProps,
  color,
  ColorProps,
  compose,
  layout,
  LayoutProps,
  ResponsiveStyleValue,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../../defaultProps'
import { transition, TransitionProps } from '../../transition'
import { whiteSpace, WhiteSpaceProps } from '../typography'
import { Button, ButtonProps } from './Button'
import {
  buttonDisabledVariants,
  ButtonLayoutProps,
  buttonLayoutVariant,
  buttonLoadingVariants,
  buttonStateMapping,
  ButtonStateProps,
  defaultButtonStyles,
} from './variants'

export type TertiaryButtonProperty = 'default' | 'inverted'

export interface TertiaryButtonProps
  extends ButtonProps,
    ButtonLayoutProps,
    LayoutProps,
    SpaceProps,
    ColorProps,
    TransitionProps,
    BoxShadowProps,
    WhiteSpaceProps {
  variant?: ResponsiveStyleValue<TertiaryButtonProperty>
}

const defaultProps = withDefaultProps<TertiaryButtonProps>({
  variant: 'default',
  layout: 'default',
})

export const TertiaryButton = styled(Button)
  .attrs<ButtonStateProps>(buttonStateMapping)
  .attrs<TertiaryButtonProps>(defaultProps)(
  defaultButtonStyles,
  (props) =>
    variant({
      scale: 'TertiaryButton.variants',
      variants: {
        default: {
          bg: 'transparent',
          boxShadow:
            props.layout === 'small'
              ? `inset 0 0 0 1px ${props.theme.colors.body}`
              : `inset 0 0 0 2px ${props.theme.colors.body}`,
        },
        inverted: {
          bg: 'transparent',
          color: 'chalk',
          boxShadow:
            props.layout === 'small'
              ? `inset 0 0 0 1px ${props.theme.colors.chalk}`
              : `inset 0 0 0 2px ${props.theme.colors.chalk}`,
        },
      },
    }),
  variant({
    prop: '$loadingState',
    scale: 'TertiaryButton.loading',
    variants: buttonLoadingVariants,
  }),
  variant({
    prop: '$disabledState',
    scale: 'TertiaryButton.disabled',
    variants: buttonDisabledVariants,
  }),
  buttonLayoutVariant,
  compose(layout, space, color, transition, boxShadow, whiteSpace),
)
