import { Box, lgBumps, xlBumps, xxlBumps } from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren } from 'react'

import { TKeyBuilder } from '../../../../../infrastructure/internationalisation'
import { MandateSection } from '../sections/MandateSection'
import { PaymentSection } from '../sections/PaymentSection'

interface CheckoutDirectDebitContentProps extends PropsWithChildren {
  tKey: TKeyBuilder
}

export const CheckoutDirectDebitContent: FunctionComponent<
  CheckoutDirectDebitContentProps
> = ({ tKey, children }) => {
  return (
    <Box pt={lgBumps}>
      <MandateSection mb={xlBumps} />
      <PaymentSection tKey={tKey} mb={xxlBumps} />
      {children}
    </Box>
  )
}
