import { css, variant } from '@wrisk/styled-system'
import styled from 'styled-components'

import { withDefaultProps } from '../defaultProps'

export type HelpTextProperty = 'default'

export interface MenuTextProps {
  variant?: HelpTextProperty
}

const defaultProps = withDefaultProps<MenuTextProps>({
  variant: 'default',
})

export const MenuText = styled.div.attrs<MenuTextProps>(defaultProps)(
  css({
    display: ['none', 'none', 'block'],
  }),
  variant({
    scale: 'HelpText',
    variants: {
      default: {},
    },
  }),
)
