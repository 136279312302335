import { DateTime, Duration } from 'luxon'

import { required } from '../helpers'
import { ValidationFunctions } from '../types'

export const datePicker: ValidationFunctions = {
  _self: {
    required,
  },
  yearMonth: {
    beforeOrEqual:
      (option: string, formKey: string) => (value: string, formValues: string) =>
        DateTime.fromISO(formValues[formKey]).startOf('month') <=
        DateTime.fromISO(value).plus(Duration.fromISO(option)),
  },
}
